<!--前十大用机大户/当年累计收入排名-->
<template>
	<div @click="sizechange" ref="MyChartDom" v-loading="loading"
		:style="{ width: '100%', height: '100%', overflow: 'hidden' }"></div>
	<span v-show="isShow!=='1'" class="vue-chart-close" @click="handleCloseChart"> </span>
</template>

<script>
import * as echarts from "echarts";
import { apiGetTopUserAndIncome } from "@/api/ChartsDataRequest";
export default {
	name: "ranked_big",
	props: ["xref", "type", "chartData"],
	emits: ["closeChart"],
	data() {
		return {
			option: {},
			loading: true,
			isShow:''
		};
	},
	methods: {
		// 图表关闭
		handleCloseChart() {
			this.$emit("closeChart");
		},
		getEqualNewlineString(params, length) {
			if (!params) return "";
			if (params.length > length) {
				//10是截取的字符串长度，根据你的情况调整数值
				params = params.slice(0, length) + "...";
			}
			return params;
		},

		// 图表创建
		createChart() {
			this.ChartDom = this.$refs.MyChartDom;
			this.MyChart = echarts.init(this.ChartDom, null);
			apiGetTopUserAndIncome().then((res) => {
				const { data = [] } = res;
				const dataList = data?.data;
				dataList.forEach((item, index) => {
					item.index = index + 1;
				});
				this.MyChart.setOption({
					title: {
						text: "前十大用机大户/当年累计收入排名",
						left: "center",
						top: "2%",
						textStyle: {
							color: "#fff",
							fontWeight: "normal",
							fontSize: "18px",
						},
					},

					legend: {
						orient: "vertical",
						// align:'right',
						right: "2%",
						top: "15%",
						bottom: "5%",
						itemGap: 30,
						padding: [0, 0, 0, 0], //可设定图例[距上方距离，距右方距离，距下方距离，距左方距离]
						data: dataList.reduce(
							(prev, cur) => prev.concat({ name: cur.name }),
							[]
						),
						formatter: (name) => {
							// let arr = [
							// 	"{NO|NO" +
							// 		dataList.find((item) => item.name === name).index +
							// 		"}{name|. " +
							// 		this.getEqualNewlineString(name, 5) +
							// 		"}{robot_num|工时数：" +
							// 		parseInt(dataList.find((item) => item.name === name).value) +
							// 		"}{value|当年累计收入：" +
							// 		parseInt(dataList.find((item) => item.name === name).income) +
							// 		"}",
							// ];
							// return arr.join("\n");
							let arr = []
							arr = [
								'{NO|NO' + dataList.find(item => item.name === name)?.index +
								'}{name|.  ' + this.getEqualNewlineString(name, 8) +
								'}{text|工时数：}{income|' + dataList.find(item => item.name === name)?.value +
								'}{text1|当年累计收入：}{value|' + dataList.find(item => item.name === name)?.income + '}'
							]
							return arr.join('\n')
						},
						itemStyle: {
							borderRadius: 1,
							borderColor: 'transparent',
							borderWidth: 1
						},
						textStyle: {
							// lineHeight: 40,
							rich: {
								NO: {
									color: '#fff',
									fontSize: 12,
									align: 'left',
									width: 30
								},
								name: {
									color: '#fff',
									fontSize: 12,
									align: 'left',
									width: 100
								},
								text: {
									color: '#fff',
									fontSize: 14,
									align: 'left',
									width: 50

								},
								income: {
									color: '#ffc824',
									fontSize: 14,
									align: 'left',
									width: 70

								},
								text1: {
									color: '#fff',
									fontSize: 14,
									align: 'left',
									width: 90

								},
								value: {
									color: '#ffc824',
									fontSize: 14,
									align: 'left'
								}
								// 	NO: {
								// 		color: "#fff",
								// 		fontSize: 12,
								// 		align: "left",
								// 		padding: [10, 0, 0, 0],
								// 		lineHeight: 40,
								// 		width: 36,
								// 	},
								// 	name: {
								// 		color: "#fff",
								// 		fontSize: 12,
								// 		align: "left",
								// 		padding: [10, 0, 0, 0],
								// 		lineHeight: 40,
								// 		width: 100,
								// 	},
								// 	robot_num: {
								// 		color: "#fff",
								// 		fontSize: 14,
								// 		align: "left",
								// 		lineHeight: 40,
								// 		width: 120,
								// 		padding: [10, 0, 0, 0],
								// 	},
								// 	value: {
								// 		color: "#fff",
								// 		fontSize: 14,
								// 		align: "left",
								// 		padding: [10, 0, 0, 0],
								// 	},
							},

						},
					},
					color: [
						"#98fc90",
						"#45b268",
						"#fc7f28",
						"#bbb401",
						"#0f7b7a",
						"#0c5489",
						"#0a2587",
						"#5a0d8a",
						"#8a0d5f",
						"#ce6765",
					],

					tooltip: {
						trigger: "item",
					},

					series: [
						{
							type: "pie",
							radius: ["40%", "70%"],
							center: ["25%", "55%"], //center: ['30%', '50%'],第一个值调整左右，第二个值调整上下，也可以设置具体数字像素值，center: [200, 300],
							avoidLabelOverlap: false,
							label: {
								show: true,
								position: "center",
								formatter: `前十位\n 客户排名`,
								color: "#fff",
								fontSize: 16,
								lineHeight: 30,
							},
							itemStyle: {
								borderRadius: 2,
								borderColor: '#fff',
								borderWidth: 1
							},
							data: dataList,
						},
					],
				});

				this.loading = false;
			});

			setTimeout(() => {
				this.$nextTick(() => {
					this.MyChart.resize();
				});
			}, 0);
		},
		sizechange() {
			this.MyChart.resize();
		},
	},
	mounted() {
		this.createChart();
		this.isShow= sessionStorage.getItem("isShow")
	},
};
</script>

<style>
.vue-chart-setting {
	position: absolute;
	width: 20px;
	height: 20px;
	top: 2px;
	right: 24px;
	background: url("../../assets/settings.svg");
	background-size: 16px 16px;
	background-position: 100% 100%;
	background-repeat: no-repeat;
	background-origin: content-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	cursor: pointer;
}

.vue-chart-close {
	position: absolute;
	width: 20px;
	height: 20px;
	top: 2px;
	right: 2px;
	background: url("../../assets/x.svg");
	background-size: 18px 18px;
	background-position: 100% 100%;
	background-repeat: no-repeat;
	background-origin: content-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	cursor: pointer;
}
</style>
