<!--每月单台机器人日工时/产能/收入峰值标-->
<template>
    <div v-loading="loading" style=" height: calc(100% - 40px);">
        <div class="title">单台机器人月工时/产能/收入峰值表</div>
        <div class="box">
            <!-- <el-select v-model="value" placeholder="请选择">
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value" />
            </el-select> -->
            <el-date-picker v-model="year" type="year" clearable format="YYYY" @change="datePickerfn" placeholder="请选择年份" />
        </div>
        <!-- :style="{ width: '100%', height: '100%', overflow: 'hidden' }" -->
        <div @click="sizechange" ref="MyChartDom" class="chart" style="width: 100%; height: 100%"></div>
    </div>

    <span v-show="isShow !== '1'" class="vue-chart-close chart" @click="handleCloseChart"> </span>
</template>

<script>
import * as echarts from "echarts";
import { robotPeak } from "@/api/ChartsDataRequest";//robotPeak
import dayjs from 'dayjs'

const chartTitle = {
    left: "left",
    textStyle: {
        fontSize: 14,
    },
};
export default {
    name: "custormer_default",
    props: ["xref", "type", "chartData"],
    emits: ["closeChart", "settingChart"],
    data() {
        return {
            loading: true,
            year: dayjs().format('YYYY'),
            option: {
                // 图表标准件
                title: {
                    text: "", // 标题
                    ...chartTitle,
                },
                tooltip: {
                    trigger: "axis",
                    axisPointer: {
                        type: "cross",
                    },
                },
                xAxis: {
                    type: "category",
                },
                yAxis: {
                    type: "value",
                },
                series: [],
            },
            chartBoxSetting: {
                type: "bar",
                name: "", // chart 标题
                xdata: [],
                series: [],
            },
            isShow: ''
        };
    },
    methods: {
        handleCloseChart() {
            this.$emit("closeChart");
        },
        handleSettingChart() {
            this.$emit("settingChart", this.chartData.i);
        },
        // 年份
        datePickerfn(day) {
            if (day) {
                this.year = dayjs(day).format('YYYY')
            } else {
                this.year = dayjs().format('YYYY')
            }
            this.CreateChart()
        },
        CreateChart() {
            this.ChartDom = this.$refs.MyChartDom;
            this.MyChart = echarts.init(this.ChartDom);
            robotPeak({
                year: this.year
            }).then((res) => {
                this.loading = false
                let arr = []
                let arr1 = []
                let arr2 = []
                res.data.data.yData.forEach(item => {
                    item.forEach(v => {
                        if (v.name === '月工时峰值') {
                            v.name = v.name + '(H)'
                            arr.push(v.data === null ? '0' : parseFloat(v.data?.value))
                        } else if (v.name === '月产能峰值') {
                            v.name = v.name + '(PCS)'
                            arr1.push(v.data === null ? 0 : parseFloat(v.data?.value))
                        } else {
                            v.name = v.name + '(元)'
                            arr2.push(v.data === null ? '0' : v.data?.value)
                        }
                    })
                });

                if (res.data.code == 200) {
                    this.MyChart.setOption({
                        title: {
                            text: "",
                            left: "center",
                            top: "3%",
                            textStyle: {
                                color: "#fff",
                                fontWeight: "normal",
                                fontSize: "18px",
                            },
                        },
                        legend: { //底部
                            data: [
                                res?.data?.data?.yData[0][0].name,
                                res?.data?.data?.yData[0][1].name,
                                res?.data?.data?.yData[0][2].name,
                            ],
                            bottom: "3%",
                            textStyle: {
                                color: "#fff",
                            },
                            width: "80%",
                        },
                        tooltip: {
                            trigger: "item",
                            axisPointer: {
                                type: "cross",
                                label: {
                                    backgroundColor: "#6a7985",
                                },
                            },
                        },
                        grid: {
                            top: "15%",
                            left: "40",
                            right: "30",
                            bottom: "18%",
                        },
                        xAxis: {
                            type: "category",
                            data: res?.data?.data?.xData,
                            axisLabel: {
                                color: "#fff",
                            },
                        },
                        yAxis: [
                            {
                                type: "value",
                                max: parseInt(Math.max(...arr)) ,
                                splitLine: {
                                    show: true,
                                    lineStyle: {
                                        color: "#999",
                                    },
                                },
                                axisLabel: {//是否显示刻度标签。
                                    show: false,
                                }
                            },
                            {
                                type: "value",
                                max: Math.max(...arr1) ,
                                splitLine: {
                                    show: false,
                                    lineStyle: {
                                        color: "#000",
                                    },
                                },
                                axisLabel: {//是否显示刻度标签。
                                    show: false,
                                }
                            },
                            {
                                type: "value",
                                max: Math.max(...arr2) ,
                                splitLine: {
                                    show: false,
                                    lineStyle: {
                                        color: "#000",
                                    },
                                },
                                axisLabel: {//是否显示刻度标签。
                                    show: false,
                                }
                            }
                        ],
                        series: [
                            // 月工时峰值
                            {
                                data: arr, // 纵坐标值
                                type: "bar",
                                yAxisIndex: 0,
                                barGap: 0.5,//柱子间间隙
                                name: res?.data?.data?.yData[0][0].name,
                                barWidth: 16,
                                itemStyle: {
                                    color: "#26c218",
                                },
                                tooltip: {
                                    formatter: function (params) {
                                        return '<div>' +
                                            '<div style="text-align: center;">' + params.name + '</span>' +
                                            ' <div><span style="float: left;margin-right: 5px;">机器人编号:</span> ' + res?.data?.data?.yData[params.dataIndex][0].data.robot_no + '</div>' +
                                            '<div><span style="float: left;margin-right: 5px;">月工时峰值:</span>' + params.value + '(H)</div>' +
                                            '</div>';
                                    }
                                },
                                label: {//顶部数值
                                    show: true,
                                    position: 'top',
                                    verticalAlign: 'top',
                                    rotate: 50,
                                    align: 'left',
                                    offset: [-6, -1], // 偏移
                                    distance: 10,
                                    color: '#fff',
                                    padding: [0, 0, 0, 0],
                                    formatter: (e) => {
                                        if (parseFloat(e.data)) {
                                            return e.data
                                        }
                                        return ''
                                    }
                                },

                            },
                            // 月产能峰值
                            {
                                data: arr1, // 纵坐标值
                                type: "bar",
                                yAxisIndex: 1,
                                barGap: 0.5,//柱子间间隙
                                name: res?.data?.data?.yData[0][1].name,
                                barWidth: 16,
                                itemStyle: {
                                    color: "#ffc824",
                                },
                                tooltip: {
                                    formatter: function (params) {
                                        return '<div>' +
                                            '<div style="text-align: center;">' + params.name + '</span>' +
                                            ' <div><span style="float: left;margin-right: 5px;">机器人编号:</span> ' + res?.data?.data?.yData[params.dataIndex][1].data.robot_no + '</div>' +
                                            '<div><span style="float: left;margin-right: 5px;">月产能峰值:</span>' + params.value + '(PCS)</div>' +
                                            '</div>';
                                    }
                                },
                                label: {//顶部数值
                                    show: true,
                                    position: 'top',
                                    verticalAlign: 'top',
                                    rotate: 50,
                                    align: 'left',
                                    offset: [-5, -1], // 偏移
                                    distance: 10,
                                    color: '#fff',
                                    formatter: (e) => {
                                        if (parseFloat(e.data)) {
                                            return e.data
                                        }
                                        return ''
                                    }
                                },
                            },
                            // 月收入峰值
                            {
                                data: arr2, // 纵坐标值
                                type: "bar",
                                yAxisIndex: 2,
                                barGap: 0.5,//柱子间间隙
                                name: res?.data?.data?.yData[0][2].name,
                                barWidth: 16,
                                itemStyle: {
                                    color: "#A2C0D8",
                                },
                                tooltip: {
                                    formatter: function (params) {
                                        return '<div>' +
                                            '<div style="text-align: center;">' + params.name + '</span>' +
                                            ' <div><span style="float: left;margin-right: 5px;">机器人编号:</span>' + res?.data?.data?.yData[params.dataIndex][2].data.robot_no + '</div>' +
                                            '<div><span style="float: left;margin-right: 5px;">月收入峰值:</span>' + params.value + '(元)</div>' +
                                            '</div>';
                                    }
                                },
                                label: {//顶部数值
                                    show: true,
                                    position: 'top',
                                    verticalAlign: 'top',
                                    rotate: 50,
                                    align: 'left',
                                    offset: [-5, -1], // 偏移
                                    distance: 10,
                                    color: '#fff',
                                    formatter: (e) => {
                                        if (parseFloat(e.data)) {
                                            return e.data
                                        }
                                        return ''
                                    }
                                },
                            },
                        ],
                    });
                }
            });

            setTimeout(() => {
                this.$nextTick(() => {
                    this.MyChart.resize();
                });
            }, 0);
        },
        sizechange() {
            // 修改 echart 大小
            this.MyChart.resize();
        },
    },
    mounted() {
        // 页面初始化后执行
        this.CreateChart();
        this.isShow = sessionStorage.getItem("isShow")
    },
};
</script>

<style scoped lang="scss">
.chart {
    width: 100%;
    padding-top: 10px;
    height: calc(100% - 40px)
}

.title {
    margin-top: 10px;
    font-size: 18px;
    color: #fff;
}

.box {
    display: flex;
    justify-content: flex-start;
    margin-left: 30px;

    // :deep(.el-select) {
    //     --el-select-border-color-hover: #fff;
    //     --el-select-input-focus-border-color: #fff;
    // }
    :deep(.el-date-editor.el-input) {
        width: 110px;
        border: 1px solid #fff;
        border-radius: 30px;
    }

    :deep(.el-input__wrapper) {
        border-radius: 30px;
    }

    :deep(.el-input__inner) {
        color: #fff;
        font-size: 16px !important;
        // text-align: center;
    }

    :deep(.el-date-editor .el-icon) {
        display: block !important;
    }
}


.vue-chart-setting {
    position: absolute;
    width: 20px;
    height: 20px;
    top: 2px;
    right: 24px;
    background: url("../../assets/settings.svg");
    background-size: 16px 16px;
    background-position: 100% 100%;
    background-repeat: no-repeat;
    background-origin: content-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    cursor: pointer;
}

.vue-chart-close {
    position: absolute;
    width: 20px;
    height: 20px;
    top: 2px;
    right: 2px;
    background: url("../../assets/x.svg");
    background-size: 18px 18px;
    background-position: 100% 100%;
    background-repeat: no-repeat;
    background-origin: content-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    cursor: pointer;
}
</style>
