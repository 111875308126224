<!-- 销售代表特别补贴公告板 -->
<template>
    <div @click="sizechange" v-loading="loading" :style="{ width: '100%', height: '100%', overflow: 'hidden' }">
        <div class="title">销售特别补贴公告板</div>
        <div class="date">补贴发布日期：{{ formattedDate }}</div>

        <div class="item-row-container">
            <div class="left">
                <div class="item" v-for="(item, index) in list.slice(0, Math.ceil(list.length / 2))" :key="index">
                    <div class="name">{{ item.name }}：</div>
                    <div class="value">
                        <div>
                            {{ item.head }}
                            <span class="head">
                                {{ item.value }}
                            </span>
                            <span class="unit">{{ item.unit }}，</span>
                        </div>
                        <div>
                            {{ item.again }}
                            <span class="head">
                                {{ item.value1 }}
                            </span>
                            <span class="unit">{{ item.unit1 }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div style="margin: 0 5px;"></div>
            <div class="right">
                <div class="item" v-for="(item, index) in list.slice(Math.ceil(list.length / 2))" :key="index">
                    <div class="name">{{ item.name }}：</div>
                    <!-- <div class="value">{{ item.value }}<span class="unit">{{ item.unit }}</span></div> -->
                    <div class="value">
                        <div>
                            {{ item.head }}
                            <span class="head">
                                {{ item.value }}
                            </span>
                            <span class="unit">{{ item.unit }}，</span>
                        </div>
                        <div>
                            {{ item.again }}
                            <span class="head">
                                {{ item.value1 }}
                            </span>
                            <span class="unit">{{ item.unit1 }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- <div class="item-row left">
            <div class="item" v-for="(item, index) in list" :key="index">
                <div class="name">{{ item.name }}：</div>
                <div class="value">{{ item.value }}<span class="unit">{{ item.unit }}</span></div>
            </div>
        </div> -->

        <el-divider class="segment" />
        <div class="describe">销售代表：活动内的前30个工单在生成交付工单后发放50%，剩余50%在完成交付收费后发放。复购工单在交付收费完成后发放全部。</div>
        <div class="describe">销售助理：活动内的前30个工单在交付收费后，每单补贴500元</div>
    </div>
    <span v-show="isShow !== '1'" class="vue-chart-close" @click="handleCloseChart"> </span>
</template>

<script>
import { labelList } from "@/api/ChartsDataRequest";

export default {
    name: "customer_status_profile",
    props: ["xref", "type", "chartData"],
    emits: ["closeChart"],
    data() {
        return {
            loading: true,
            list: [],
            formattedDate: '',
            isShow: ''
        };
    },
    methods: {
        // 图表关闭
        handleCloseChart() {
            this.$emit("closeChart");
        },
        formatDate(dateString) {
            let date = new Date(dateString);
            return date.toLocaleDateString('zh-CN', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
            }).replace(/\//g, '年').replace(/年(\d{2})$/, '月$1日');
            // .replace(/\//g, '年').replace(/-/g, '月') + '日';
        },
        createChart() {
            this.loading = false;
            labelList({
                dict_identification: 'sale_notice',
                page: 1,
                page_rows: 1000
            }).then((res) => {
                if (res.data.code == 200) {
                    this.list = res.data.data.list.map(item => {
                        const [namePart, detailsPart] = item.dict_label.split('：'); // 分离出 name 和剩余部分
                        const details = [...detailsPart.matchAll(/(\D*)(\d+)(\D*)/g)];
                        
                        return {
                            // name: item.dict_label.split('：')[0],
                            // value: item.dict_label.replace(/\D+/g, ''),
                            // unit: item.dict_label.match(/\d+(.*)/)[1],again
                            name:namePart,
                            head: details[0] ? details[0][1].trim() : '',  // 提取 "首单"
                            value: details[0] ? details[0][2] : '',  // 提取数字 "8000"
                            unit: details[0] ? details[0][3].split('，')[0].trim() : '',// 提取单位 "元"
                            again: details[0] ? details[0][3].split('，')[1].trim() : '',  // 提取 "复购"
                            value1: details[1] ? details[1][2] : '',  // 提取数字 "5000"
                            unit1: details[1] ? details[1][3].trim() : '',  // 提取单位 "元"
                        }
                    })

                    // 时间处理
                    let obj = res.data.data.list.find(item => item.gmt_create !== item.gmt_modified)

                    if (!obj) {
                        this.formattedDate = this.formatDate(obj.gmt_modified);
                    } else {
                        this.formattedDate = this.formatDate(res.data.data.list[0]?.gmt_modified);
                    }
                    this.loading = false;
                }
            })
        },

        sizechange() {
            this.MyChart.resize();
        }
    },
    mounted() {
        this.createChart();
        this.isShow = sessionStorage.getItem("isShow")
    },
};
</script>

<style scoped lang="scss">
.vue-chart-setting {
    position: absolute;
    width: 20px;
    height: 20px;
    top: 2px;
    right: 24px;
    background: url("../../assets/settings.svg");
    background-size: 16px 16px;
    background-position: 100% 100%;
    background-repeat: no-repeat;
    background-origin: content-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    cursor: pointer;
}

.title {
    height: 68px;
    line-height: 68px;
    font-size: 20px;
    text-align: center;
    color: #F9B707;
}

.date {
    color: #86781E;
    text-align: center;
    font-size: 18px;
    margin-bottom: 8px;
}



// .item-row {
//     padding: 0 10px;
//     display: flex;
//     flex-wrap: wrap;
//     gap: 10px;
//     justify-content: space-between;
//     /* 使项元素在行内左右对齐 */

//     /* 栅格布局的行与列间隙 */
//     .item {
//         // width: 220px;
//         // width: 220px;
//         width: 43%;
//         height: 60px;
//         line-height: 60px;
//         background-color: #005F59;
//         color: #fff;
//         border-radius: 10px;
//         padding: 0 10px;
//         display: flex;
//         justify-content: space-between;

//         .name {
//             font-size: 14px;
//         }

//         .value {
//             font-size: 24px;
//             color: #F9B707;

//             .unit {
//                 color: #fff;
//             }
//         }

//         .value span {
//             margin-left: 5px;
//             font-size: 14px;
//         }
//     }
// }

.item-row-container {
    display: flex;
    justify-content: space-between;
    padding: 0 10px;

    .left {
        flex: 1;
    }

    .right {
        flex: 1;
    }
}

.item-row {
    padding: 0 14px;
}

.item {
    // width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #005F59;
    border-radius: 8px;
    color: #fff;
    margin-top: 10px;
    height: 40px;
    line-height: 40px;
    padding: 0 10px;

    .name {
        font-size: 14px;
    }

    .value {
        display: flex;
        align-items: center;
        font-size: 14px;

        .head {
            font-size: 24px;
            color: #F9B707;
        }

        .unit {
            font-size: 14px;
            color: #fff;
        }
    }

    .value span {
        // margin-left: 5px;
        // font-size: 14px;
    }
}

.segment {
    width: 95%;
    margin: 20px auto 0px;
    border-color: #3C5B58;
}

.describe {
    padding: 0 10px;
    color: #F9B707;
    font-size: 18px;
    margin-top: 8px;
    text-align: left;
}
</style>