<!--本月工位工时Top10-->
<template>
	<div class="strip_wrap" v-loading="loading">
		<div class="son-title">本月工位工时排名TOP10</div>
		<div class="tips" v-if="workedTimeTopTen.length===0" ></div>
		<div class="strip_item" v-for="(item, i) in workedTimeTopTen" :key="i">
			<div class="strip_text">
				<div>No.{{ i + 1 }}</div>
				<div>{{ item.name }}</div>
			</div>
			<div class="strip">
				<div>
					<span
						:style="{ width: (this.maxTime!==0?item.value / this.maxTime:item.value) * 100 + '%' }"
					></span>
				</div>
				<div style="width: 60px">{{ item.value }}h</div>
			</div>
		</div>
	</div>
	<span v-show="isShow!=='1'" class="vue-chart-close" @click="handleCloseChart"></span>
	<img src="@/assets/sort.png" @click="sort" class="sort" >
</template>
<script>
import { apiCurrentMonthWorkingTime } from "@/api/ChartsDataRequest";
import dayjs from "dayjs"
export default {
	name: "day_workstation_working_hours_ranking",
	props: [],
	emits: ["closeChart"],
	data() {
		return {
			workedTimeTopTen: [],
			maxTime: "",
			loading: true,
			order:false,
			isShow:''
		};
	},
	methods: {
		// 图表关闭
		handleCloseChart() {
			this.$emit("closeChart");
		},
		// 图表创建
		createChart() {
			this.loading = false;
			const monthStartTimestamp=dayjs().startOf('month').unix()
			apiCurrentMonthWorkingTime({
				start_time:monthStartTimestamp,
        field:'actual_production_time',
        page_rows:'10',
				order:this.order?'asc':'desc' //asc:升序 desc:降序
			}).then((res) => {
				if (res.data.code == 200) {
					this.workedTimeTopTen = res?.data?.data || [];

					this.maxTime= Math.max.apply(Math,this.workedTimeTopTen.map(item => { return item.value }))
				} 
				this.loading = false;
			});
		},
		sort(){
			this.order= !this.order
			this.createChart();
		}
	},
	mounted() {
		this.createChart();
		this.isShow= sessionStorage.getItem("isShow")
	},
};
</script>
<style lang="scss" scoped>
.son-title {
	padding: 8px;
	font-size: 18px;
}
.son-img{
float: right;
width: 25px;
height: 25px;
cursor:pointer;
}
.strip_wrap {
	padding: 0 20px;
	color: #fff;
	--bg-start: rgb(226, 208, 96); //设置开始的渐变颜色
	--bg-end: rgb(226, 208, 96); //设置结束的渐变颜色
}

.strip_wrap .strip_item .strip_text {
	display: flex;
	font-size: 14px;
	line-height: 30px;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}

.strip_wrap .strip_item .strip_text :nth-child(1) {
	color: #E2D060;
	padding-right: 10px;
}
.strip_wrap .strip_item .strip {
	flex: 1;
	// height: 10px;
	display: flex;
	align-items: center;
}
.strip_wrap .strip_item .strip :nth-child(1) {
	background-color: #2b4e4a;
	flex: 1;
	height: 10px;
	position: relative;
}
.strip_wrap .strip_item .strip :nth-child(1) span {
	display: block;

	height: 10px;
	width: 90%;
	position: absolute;
	left: 0;
	/* Firefox 3.6+ */
	background: -moz-linear-gradient(
		to right,
		var(--bg-start) 30%,
		var(--bg-end) 100%
	);

	/* Safari 4-5, Chrome 1-9 */
	background: -webkit-gradient(
		to right,
		var(--bg-start) 30%,
		var(--bg-end) 100%
	);

	/* Safari 5.1+, Chrome 10+ */
	background: -webkit-linear-gradient(
		to right,
		var(--bg-start) 30%,
		var(--bg-end) 100%
	);

	/* Opera 11.10+ */
	background: -o-linear-gradient(
		to right,
		var(--bg-start) 30%,
		var(--bg-end) 100%
	);

	background: linear-gradient(
		to right,
		var(--bg-start) 30%,
		var(--bg-end) 100%
	);

	/*IE<9>*/
	filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0, startColorstr=var(--bg-start),endColorstr=var(--bg-end));

	/*IE8+*/
	-ms-filter: "progid:DXImageTransform.Microsoft.gradient(GradientType=0, startColorstr=var(--bg-start), endColorstr=var(--bg-end))";
}
.strip_wrap .strip_item .strip :nth-child(2) {
	min-width: 30px;
	padding: 0 10px;
	text-align: center;
}
</style>
