<!-- 本月单机平均预期收入 -->
<template>
	<div @click="sizechange" v-loading="loading" :style="{ width: '100%', height: '100%', overflow: 'hidden' }">
		<div class="circle-title">本月单机平均预期收入</div>
		<div class="today_work_hours_pie">
			<div id="moon_stand_alone_average_expected_income"></div>
			<div class="text-item">
				<div>环比上月</div>
				<!--  -->
				<div class="today-num" :style="{color:(month_rate.indexOf('-',0)==0?'#3FA864':'')}">{{ month_rate }}</div>
			</div>
			<div class="text-item">
				<div>上月总收入</div>
				<div class="today-num" :class="last_all_month_income>=0?'today-num-green':''">{{ last_all_month_income }}</div>
			</div>
		</div>
	</div>
	<span v-show="isShow!=='1'" class="vue-chart-close" @click="handleCloseChart"> </span>
</template>

<script>
import { moon_stand_alone_average_expected_income } from "@/api/ChartsDataRequest";
import Progress from "./todayWorkHour.js";
let progressDom = null;
let progressData = {};
export default {
	name: "customer_status_profile",
	props: ["xref", "type", "chartData"],
	emits: ["closeChart"],
	data() {
		return {
			option: {},
			loading: false,
			month_rate: '',
			last_all_month_income: 0,
			isShow:''
		};
	},
	methods: {
		// 图表关闭
		handleCloseChart() {
			this.$emit("closeChart");
		},

		// 图表创建
		createChart() {
			progressDom = new Progress(progressData);
			progressDom.init();
		},
		sizechange() {
			progressDom.remove();
			setTimeout(() => {
				progressDom = new Progress(progressData);
				progressDom.init();
			}, 200);
		},
	},
	async mounted() {
		const res = await moon_stand_alone_average_expected_income()
		if (res.data.code == 200) {
				this.month_rate = res.data.data.month_rate // 环比上月
				this.last_all_month_income = Math.round(res.data.data.last_all_month_income)  // 上月总收入
				progressData = {
					dom: document.getElementById("moon_stand_alone_average_expected_income"),
					innerRadius: 60, //内圆半径
					outerRadius: 70, //外圆半径
					fontSize: 22,
					fontColor: "#fff",
					suffix: " ",
					// extra: '<div style="color:#fff;font-size:14px;line-height:20px">本月单机平均预期收入</div>',
					duration: 1000,
					value:res.data.data.avg_income/res.data.data.standard_income,
					customRingValue:res.data.data.avg_income+'<div style="color:#fff;font-size:14px;line-height:20px">本月单机平均预期收入</div>',
					textDomClass:'chartCustomRingValue_center',
					color: [
						["rgb(236, 189, 70)", 0],
						["rgb(253, 170, 60 )", 1],
					],
				};
			}
		setTimeout(() => {
			this.createChart();
		}, 800);
		this.isShow= sessionStorage.getItem("isShow")
	},
};
</script>

<style>
.vue-chart-setting {
	background-color: rgb(253, 184, 8);
	position: absolute;
	width: 20px;
	height: 20px;
	top: 2px;
	right: 24px;
	background: url("../../assets/settings.svg");
	background-size: 16px 16px;
	background-position: 100% 100%;
	background-repeat: no-repeat;
	background-origin: content-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	cursor: pointer;
}

.vue-chart-close {
	position: absolute;
	width: 20px;
	height: 20px;
	top: 2px;
	right: 2px;
	background: url("../../assets/x.svg");
	background-size: 18px 18px;
	background-position: 100% 100%;
	background-repeat: no-repeat;
	background-origin: content-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	cursor: pointer;
}

.circle-title {
	color: #fff;
	font-size: 18px;
	margin-top: 8px;
}

#moon_stand_alone_average_expected_income {
	height: 70%;
	width: 100%;
	/* background: rgb(248, 3, 3); */
}

.today_work_hours_pie {
	height: 100%;
	width: 100%;
	flex-direction: column;
}

.today_work_hours_pie .text-item {
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	font-size: 14px;
	color: #fff;
}

.today_work_hours_pie .text-item>div:first-child {
	color: #fff;
	font-size: 14px;
}

.today_work_hours_pie .text-item>div:last-child {
	/* font-size: 18px; */
	color: #f9b707;
	padding-left: 20px;
}

.today-num {
	font-size: 18px;
}
.today-num-green{
	color: #1FA41C;
}
</style>
