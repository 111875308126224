<!--本月总工时-->
<template>
	<div class="chart-drobot-title">本月总工时</div>
	
	<div class="robot-data">
		<div id="totals"></div>
		<div class="right-text">
			<div class="text-item">
				<div class="text-div">上周同期总工时 <span>{{ lastWeek+'h' }}</span></div>
				<div class="text-div">本周总工时 <span>{{ workHour+'h' }}</span></div>
			</div>
		</div>
	</div>

	<div class="robot-data-bot">
		<div class="text-item">
			<div :style="{color:(lastMonth.indexOf('-',0)==0?'#3FA864':'')}">{{lastMonth }}</div>
			<div class="son-title">环比上月</div>
		</div>
		<div class="text-item">
			<div :style="{color:(weekRate.indexOf('-',0)==0?'#3FA864':'')}">{{weekRate.indexOf('-',0)!==0?'+'+weekRate:weekRate}}</div>
			<div class="son-title">环比上周</div>
		</div>
	</div>
	<span v-show="isShow!=='1'" class="vue-chart-close" @click="handleCloseChart"> </span>
</template>

<script>
import {apiGetMonthAssembleTime} from "@/api/ChartsDataRequest";
import Progress from "./todayWorkHour.js";
let progressDom = null;
let progressData = {};
export default {
	name: "customer_status_profile",
	props: ["xref", "type", "chartData"],
	emits: ["closeChart"],
	data() {
		return {
			option: {},
			loading: false,
      lastWeek:'',
      workHour:0,
			lastMonth:'',
			weekRate:'',
			isShow:''
		};
	},
	methods: {
		// 图表关闭
		handleCloseChart() {
			this.$emit("closeChart");
		},

		// 图表创建
		createChart() {
			progressDom = new Progress(progressData);
			progressDom.init();
		},
		sizechange() {
			progressDom.remove();
			setTimeout(() => {
				progressDom = new Progress(progressData);
				progressDom.init();
			}, 200);
		},
	},
	async mounted() {
   const res =await apiGetMonthAssembleTime()
	if(res.data.code === 200){
				this.lastWeek = res.data.data.last_week_work_hour //上周同期总工时
				this.workHour = res.data.data.week_work_hour //本周总工时
				this.lastMonth = res.data.data.month_rate //环比上月
				this.weekRate = res.data.data.week_rate //环比上周
        progressData = {
        dom: document.getElementById("totals"),
        innerRadius: 60, //内圆半径
        outerRadius: 70, //外圆半径
        fontSize: 24,
        fontColor: "#fff",
        suffix: " ",
        duration: 1000,
				isNeedLineHeight:false,
				// value: Math.floor(res.data.data.work_hour/res.data.data.standard_hour),
				value:res.data.data.work_hour/res.data.data.standard_hour,
				customRingValue:res.data.data.work_hour+'h'+'<div style="color:#fff;font-size:12px">本月总工时</div>',
        textDomClass:'chartCustomRingValue_center',
				color: [
          ["rgb(246, 222, 98)", 0],
          ["rgb(246, 170, 80)", 1],
        ],
				};
	}
		// setTimeout(() => {
			this.createChart();
		// }, 800);
		this.isShow= sessionStorage.getItem("isShow")
	},
};
</script>

<style lang="scss" scoped>
.chart-drobot-title {
	color: #fff;
	font-size: 18px;
	padding-top: 8px;
}
.son-title {
	font-size: 14px;
}


.robot-data {
	display: flex;
	align-items: center;
	justify-content: center;
	height: calc(100% - 120px);
	width: 100%;
	position:relative;

	&::after {
		content: "";
		display: block;
		width: 80%;
		height: 2px;
		background-color: #69827e;
		position: absolute;
		bottom: -10px;
	}

	.right-text {
		color: #fff;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		margin: 0;
		.text-item {
			text-align: center;
			// padding: 0;

			.text-div{
				font-size: 16px;
				color: white;
				span{
				font-size: 18px;
				margin-left: 5px;
				color: #f9b707;
			}
			}
		}
	}
}

#totals{
	width: 40%;
	height:70%;
}
.robot-data-bot {
	display: flex;
	padding: 20px 0;

	.text-item {
		flex: 1;
		text-align: center;
		font-size: 12px;
		color: #fff;

		div:first-child {
			font-size: 24px;
			color: #f9b707;
		}
	}
}
</style>
