<!-- 本月总稼动率 -->
<template>
	<div class="chart-drobot-title">本月总稼动率</div>
	
	<div class="robot-data">
		<div id="utilization"></div>
		<div class="right-text">
			<div class="text-item">
				<div class="text-div">上周稼动率 <span>{{ lastWeek }}</span></div>
				<!-- workHour.indexOf('%')!==-1?workHour:workHour+'%' -->
				<div class="text-div">本周稼动率 <span>{{ workHour }}</span></div>
			</div>
		</div>
	</div>

	<div class="robot-data-bot">
		<div class="text-item">
			<div>{{ lastMonth }}</div>
			<div class="son-title">环比上月{{ lastMonth }}</div>
		</div>
		<div class="text-item">
			<div>{{weekRate}}</div>
			<div class="son-title">环比上周</div>
		</div>
	</div>
	<span v-show="isShow!=='1'" class="vue-chart-close" @click="handleCloseChart"> </span>
</template>

<script>
import {month_assemble_utilization} from "@/api/ChartsDataRequest";
import Progress from "./todayWorkHour.js";
let progressDom = null;
let progressData = {};
export default {
	name: "customer_status_profile",
	props: ["xref", "type", "chartData"],
	emits: ["closeChart"],
	data() {
		return {
			option: {},
			loading: false,
      lastWeek:0,
      workHour:0,
			lastMonth:'',
			weekRate:'',
			isShow:''
		};
	},
	methods: {
		// 图表关闭
		handleCloseChart() {
			this.$emit("closeChart");
		},

		// 图表创建
		createChart() {
			progressDom = new Progress(progressData);
			progressDom.init();
		},
		sizechange() {
			progressDom.remove();
			setTimeout(() => {
				progressDom = new Progress(progressData);
				progressDom.init();
			}, 200);
		},
	},
	async mounted() {
   const res =await month_assemble_utilization()
	if(res.data.code === 200){
			this.lastWeek = res.data.data.last_week_efficient//上周稼动率
			this.workHour = res.data.data.week_efficient //本周稼动率
			this.lastMonth = res.data.data.month_rate //环比上月
			this.weekRate = res.data.data.week_rate //环比上周
      progressData = {
      dom: document.getElementById("utilization"),
      innerRadius: 60, //内圆半径
      outerRadius: 70, //外圆半径
      fontSize: 24,
      fontColor: "#fff",
      suffix: " ",
      duration: 1000,
      isNeedLineHeight:false,
			value:parseInt(res.data.data.total_efficient.replace('%',''))/100,
      customRingValue:res.data.data.total_efficient+'<div style="color:#fff;font-size:14px">本月总稼动率</div>',
      textDomClass:'chartCustomRingValue_center',
      color: [
        ["rgb(129, 218, 40)", 0],
        ["rgb(129, 205, 38)", 0.5],
        ["rgb(129, 190, 30)", 1],
        ],
      };
	}
		// setTimeout(() => {
			this.createChart();
		// }, 800);
		this.isShow= sessionStorage.getItem("isShow")
	},
};
</script>

<style lang="scss" scoped>
.chart-drobot-title {
	color: #fff;
	font-size: 18px;
	padding-top: 8px;
}
.son-title {
	font-size: 14px;
}


.robot-data {
	display: flex;
	align-items: center;
	justify-content: center;
	height: calc(100% - 120px);
	width: 100%;
	position:relative;

	&::after {
		content: "";
		display: block;
		width: 80%;
		height: 2px;
		background-color: #69827e;
		position: absolute;
		bottom: -10px;
	}

	.right-text {
		color: #fff;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		margin: 0;
		.text-item {
			text-align: center;
			padding: 0;
			.text-div{
				font-size: 16px;
				color: white;
				span{
				font-size: 20px;
				margin-left: 10px;
				color: #f9b707;
			}
			}
		}
	}
}

#utilization{
	width: 40%;
	height:70%;
}
.robot-data-bot {
	display: flex;
	padding: 20px 0;

	.text-item {
		flex: 1;
		text-align: center;
		font-size: 12px;
		color: #fff;

		div:first-child {
			font-size: 24px;
			color: #23C215FF;
		}
	}
}
</style>
