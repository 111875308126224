<template>
	<div
		@click="sizechange"
		ref="MyChartDom"
		v-loading="loading"
		:style="{ width: '100%', height: '100%', overflow: 'hidden' }"
	></div>
	<span v-show="isShow!=='1'" class="vue-chart-close" @click="handleCloseChart"> </span>
</template>

<script>
import * as echarts from "echarts";
import { month_expect_income_analyze } from "@/api/ChartsDataRequest";

export default {
	name: "customer_status_profile",
	props: ["xref", "type", "chartData"],
	emits: ["closeChart"],
	data() {
		return {
			option: {},
			loading: true,
			isShow:''
		};
	},
	methods: {
		// 图表关闭
		handleCloseChart() {
			this.$emit("closeChart");
		},
		getEqualNewlineString(params, length) {
			if (!params) return "";
			if (params.length > length) {
				//10是截取的字符串长度，根据你的情况调整数值
				params = params.slice(0, length) + "...";
			}
			return params;
		},

		// 图表创建
		createChart() {
			this.ChartDom = this.$refs.MyChartDom;
			this.MyChart = echarts.init(this.ChartDom, null);
			month_expect_income_analyze().then((nres) => {
				if (nres?.data?.code === 200) {
					const res = {
						xData: nres?.data?.data.xData,
						yData: nres?.data?.data.yData,
					};
				
					const tData = res.xData.map(item=>{
						const t=item.split('-')
						return parseInt(t[t.length-1])
					})
					// res.xData.reduce((prev, cur) => prev.concat(cur), []);
					const xData1 = res.yData.find(
						(item) => item.name === "本月每日预期收入"
					).data;
					const xData2 = res.yData.find(
						(item) => item.name === "本月标准预期收入"
					).data;
					const xData3 = res.yData.find(
						(item) => item.name === "本月每日平均收入"
					).data;

					const option = {
						title: {
							text: "本月预期收入分析",
							left: "center",
							top: "2%",
							textStyle: {
								color: "#fff",
								fontWeight: "normal",
								fontSize: "18px",
							},
						},
						tooltip: {
							trigger: "axis",
							textStyle: {
								align: "left",
							},
							axisPointer: {
								type: "cross",
								label: {
									backgroundColor: "#6a7985",
								},
							},
							formatter: function(params) {
								let relVal = params[0].name;
								for (let i = 0; i < params.length; i++) {
									if(params[i].seriesName === '本月每日预期收入'){
										relVal +=
										'<div style="width:210px"><span style="display: inline-block;width: 10px;border: 2px solid #c5daf1;margin-bottom:4px;margin-right:5px"></span>' +
										params[i].seriesName +
										'<span style="text-align:right;float:right">' +
										params[i].value +
										"</span>" +
										"</div>";
									}else if(params[i].seriesName === '本月标准预期收入'){
										relVal +=
										'<div style="width:210px"><span style="display: inline-block;width: 10px;border: 2px dashed #edb212;margin-bottom:4px;margin-right:5px"></span>' +
										params[i].seriesName +
										'<span style="text-align:right;float:right">' +
										params[i].value +
										"</span>" +
										"</div>";
									}else{
										relVal +=
										'<div style="width:210px"><span style="display: inline-block;width: 10px;border: 2px solid #4aca48;margin-bottom:4px;margin-right:5px"></span>' +
										params[i].seriesName +
										'<span style="text-align:right;float:right">' +
										params[i].value +
										"</span>" +
										"</div>";
									}
								}
								return relVal;
							}
						},
						legend: {
							bottom: "16",
							textStyle: {
								color: "#fff",
								fontSize: "14px",
								padding: [0, 0, 0, 0],
							},
							data: [
								{
									icon: "path://M30.6983 4.13281H0.164062V0.132812H30.6983V4.13281Z",
									name: "本月每日预期收入",
								},
								{
									icon: "path://M4.52772 4.13281H0.210938V0.132812H3.52772V4.13281ZM16.7948 4.13281H10.1613V0.132812H16.7948V4.13281ZM26.7452 4.13281H23.4284V0.132812H26.7452V4.13281Z",
									name: "本月标准预期收入",
								},
								{
									icon: "path://M30.6983 4.13281H0.164062V0.132812H30.6983V4.13281Z",
									name: "本月每日平均收入",
								},
							],
						},
						grid: {
							top: 60,
							left: 40,
							right: 50,
							bottom: 60,
							containLabel: true,
						},
						xAxis: {
							type: "category",
							boundaryGap: false,
							axisLabel: {
								color: "#fff", // 更改坐标轴文字颜色
								fontSize: 14, // 更改坐标轴文字大小
							},
							data: tData,
						},
						yAxis: [
							{
								type: "value",
								position: "left",
								splitLine: {
									//Y轴线的颜色
									show: true,
									interval: "auto",
									lineStyle: {
										color: ["#546f6d"],
									},
								},
								axisTick: {
									show: false,
								},
								//y轴左侧文字颜色
								axisLine: {
									onZero: true,
									lineStyle: {
										color: "#fff",
									},
								},
								// y轴左侧文字大小
								axisLabel: {
									margin: 10,
									textStyle: {
										fontSize: 12,
									},
								},
							},
						],
						series: [
							{
								areaStyle: {
									color: {
										type: "linear",
										shadowOffsetY: 400,
										x: 0,
										y: 0,
										x2: 0,
										y2: 1,
										colorStops: [
											{
												offset: 0,
												color: "rgba(197, 218, 241, 0.5)", // 0% 处的颜色
											},
											{
												offset: 1,
												color: "rgba(197, 218, 241, 0)", // 100% 处的颜色
											},
										],
										global: false, // 缺省为 false
									},
								},
								name: "本月每日预期收入",
								type: "line",
								// stack: 'Total',
								// yAxisIndex: 0,
								// symbol: 'none',
								//  'circle', 'rect', 'roundRect', 'triangle', 'diamond', 'pin', 'arrow', 'none'
								// smooth: true,//是否平滑曲线显示
								symbolSize: 8,
								lineStyle: {
									color: "#c5daf1",
									width: 3,
									// type: 'dotted'
								},
								itemStyle: {
									color: "#c5daf1",
								},
								emphasis: {
									focus: "series",
								},
								data: xData1,
							},
							{
								name: "本月标准预期收入",
								type: "line",
								// stack: 'Total',
								symbol: "none",
								symbolSize: 8,
								lineStyle: {
									color: "#edb212",
									width: 3,
									type: "dashed",
								},
								itemStyle: {
									//标准工时
									normal: {
										color: "#edb212",
									},
								},
								emphasis: {
									focus: "series",
								},
								data: xData2,
							},
							{
								name: "本月每日平均收入",
								type: "line",
								// stack: 'Total',
								// yAxisIndex: 2,
								symbol: "none",
								symbolSize: 10,
								emphasis: {
									focus: "series",
								},
								itemStyle: {
									normal: {
										color: "#4aca48",
									},
								},
								lineStyle: {
									color: "#4aca48",
									width: 3,
									// type: "dashed",
								},
								data: xData3,
							},
						],
					};
					this.MyChart.setOption(option);
					this.loading = false;
				}
			});
			setTimeout(() => {
				this.$nextTick(() => {
					this.MyChart.resize();
				});
			}, 0);
		},
		sizechange() {
			this.MyChart.resize();
		},
	},
	mounted() {
		this.createChart();
		this.isShow= sessionStorage.getItem("isShow")
	},
};
</script>

<style>
.vue-chart-setting {
	position: absolute;
	width: 20px;
	height: 20px;
	top: 2px;
	right: 24px;
	background: url("../../assets/settings.svg");
	background-size: 16px 16px;
	background-position: 100% 100%;
	background-repeat: no-repeat;
	background-origin: content-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	cursor: pointer;
}

.vue-chart-close {
	position: absolute;
	width: 20px;
	height: 20px;
	top: 2px;
	right: 2px;
	background: url("../../assets/x.svg");
	background-size: 18px 18px;
	background-position: 100% 100%;
	background-repeat: no-repeat;
	background-origin: content-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	cursor: pointer;
}
</style>
