<template>
	<div>
		<!-- v-show="this.invesValue != '1'" -->
		<!-- <div class="chart-catgory" v-show="false">
			<p class="group-title">
				{{ groupTitle }}
			</p>
			<div class="right-icon">
				<el-dropdown @command="actionGroup">
					<span class="el-dropdown-link">
						<div class="top-tool-group" title="图表组管理"></div>
					</span>
					<template v-slot:dropdown>
						<el-dropdown-menu>
							<el-dropdown-item command="add">新增分组</el-dropdown-item>
							<el-dropdown-item command="update">修改分组</el-dropdown-item>
							<el-dropdown-item command="del">删除分组</el-dropdown-item>
						</el-dropdown-menu>
					</template>
</el-dropdown>
<img src="../assets/refresh-chart.png" width="20" height="20" @click="refreshChartBoxes" title="刷新" />
<img src="../assets/add-chart.png" @click="selectChartBox" width="20" height="20" title="新增图表" />
</div>
</div> -->
		<!-- 经营看板 -->
		<div class="chart-catgory" :class="[fullScreen ? 'catgory' : 'catgorys']">

			<el-select v-model="value" class="autoWidth-select" placeholder="请选择..." ref="elOptionRef"
				:teleported="false" @change="selectChange">
				<template #prefix>
					<div :style="{ width: value.length * 15 + 'px' }">
						{{ (option.find(s => s.value === value) || {}).label }}
					</div>
				</template>
				<el-option v-for="(item, i) in option" :key="item.value" :value="item.value" :disabled="item.disabled">
					<div class="flex align_items_center" v-if="i < option.length - 1">
						<div class="flex_1 c_name">
							{{ item.value }}
						</div>
						<svg @click.stop="handleOptionItem(item)" width="20" height="20" viewBox="0 0 16 16" fill="none"
							xmlns="http://www.w3.org/2000/svg">
							<g clip-path="url(#clip0_1917_249269)">
								<path
									d="M11.6 8.00446V6.00558M11.6 6.00558L7.48571 2.00781M11.6 6.00558H7.48571V2.00781M7.48571 2.00781H2V11.716C2 12.9781 3.02306 14.0011 4.28508 14.0011H6.8M12.6286 9.00391L14 10.3365L10.2286 14.0011H8.85714V12.6685L12.6286 9.00391Z"
									stroke="#F9B707" stroke-width="1.14254" />
							</g>
							<defs>
								<clipPath id="clip0_1917_249269">
									<rect width="16" height="16" fill="white" />
								</clipPath>
							</defs>
						</svg>
					</div>
					<div class="add" v-else @click="handleAdd">
						<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
							<g clip-path="url(#clip0_1716_215231)">
								<circle cx="7.99777" cy="8.00558" r="6.4" stroke="#002925" stroke-width="0.914286" />
								<path d="M4.57031 8.00781H11.4275" stroke="#002925" stroke-width="0.914286"
									stroke-linecap="round" />
								<path d="M8 4.57812L8 11.4353" stroke="#002925" stroke-width="0.914286"
									stroke-linecap="round" />
							</g>
							<defs>
								<clipPath id="clip0_1716_215231">
									<rect width="16" height="16" fill="white" />
								</clipPath>
							</defs>
						</svg>
						<span>新建看板</span>
					</div>
				</el-option>
			</el-select>
		</div>

		<el-scrollbar :height="height" ref="scrollbarRef" @scroll="scroll">

			<grid-layout :layout="layout" :col-num="12" :row-height="30"
				:is-draggable="isShow == '1' ? (draggable = false) : draggable"
				:is-resizable="isShow == '1' ? (resizable = false) : resizable" :vertical-compact="true"
				:use-css-transforms="false" :margin="[10, 10]" class="group-box" @layout-updated="layoutUpdated"
				@layout-ready="layoutReady" id="chartWrap">
				<!-- <div class="model"></div> -->
				<div id="scrollbarHeight"
					style="position: relative;width: 100%;height: 100%;z-index: -1000;left: 0;top: 0;">
				</div>
				<grid-item v-for="item in layout" :static="item.static" :x="item.x" :y="item.y" :w="item.w" :h="item.h"
					:i="item.i" :key="item.i" :chartBoxKey="item.chartBoxKey" :min-w="item.minW" :min-h="item.minH"
					@resize="sizeAutoChange(item.chartBoxKey)" @resized="resizedEvent(item.chartBoxKey)"
					@move="moveChart(item.i, item.x, item.y, item.chartBoxKey)"
					@moved="movedEvent(item.i, item.x, item.y, item.chartBoxKey)"
					drag-allow-from=".vue-draggable-handle">
					<div class="vue-draggable-handle" title="按此处拖动">...</div>
					<component v-if="item.chartBoxKey &&
						Object.keys(this.$options.components).includes(item.chartBoxKey)
					" :is="item.chartBoxKey" :chartData="item" :ref="item.chartBoxKey" @closeChart="newCloseChart(item)"
						:name=item.name :value=item.value :group_to_board_obj="item.group_to_board_obj"
						@deliveryDelChart="deliveryDelChart(item)"
						@addDeliveryChart="val => addDeliveryChart(val, item)" />
					<div v-else>
						<span class="vue-chart-close" @click="newCloseChart(item)" title="点击此处关闭">
						</span>
						<span class="no-chart-desc">No chart - {{ item.chartBoxKey }}</span>
					</div>
				</grid-item>

				<el-dialog :title="groupFormTitle" v-model="actionGoupVisible" width="30%" class="chart-setting">
					<el-form :model="ruleForm" :rules="rules" ref="ruleFormdata">
						<el-form-item label="分组名称" :label-width="formLabelWidth" prop="name" placeholder="请分组名称">
							<el-input autocomplete="off" v-model="ruleForm.name" class="input-ctrol"></el-input>
						</el-form-item>
						<!-- 添加 -->
						<el-form-item label="看板刷新时间" :label-width="formLabelWidth" prop="sort">
							<el-select v-model="Time" placeholder=" " class="optionsTime" @change="elOptionTiem">
								<el-option v-for="item in optionsTime" :key="item.value" :label="item.label"
									:value="item.value" />
							</el-select>
							<span style="color: rgb(0, 95, 89);margin:0 5px;">分钟</span>
							<svg width="16" height="16" viewBox="0 0 16 16" fill="none"
								xmlns="http://www.w3.org/2000/svg">
								<g clip-path="url(#clip0_1777_214686)">
									<circle cx="8" cy="8" r="6.6" stroke="#F9B707" stroke-width="0.8" />
									<path
										d="M7.50745 4.86H8.43745L8.27745 10.08H7.65745L7.50745 4.86ZM7.96745 10.81C8.13745 10.81 8.28745 10.86 8.40745 10.98C8.51745 11.09 8.57745 11.23 8.57745 11.4C8.57745 11.58 8.51745 11.72 8.40745 11.83C8.28745 11.94 8.13745 12 7.96745 12C7.79745 12 7.65745 11.94 7.53745 11.83C7.41745 11.71 7.36745 11.57 7.36745 11.4C7.36745 11.23 7.41745 11.09 7.53745 10.98C7.65745 10.86 7.79745 10.81 7.96745 10.81Z"
										fill="#F9B707" />
								</g>
								<defs>
									<clipPath id="clip0_1777_214686">
										<rect width="16" height="16" fill="white" />
									</clipPath>
								</defs>
							</svg>
							<span
								style="color: rgba(0, 95, 89,0.7);margin-left: 5px;font-size:12px;">当刷新时间为0时，看板不自动刷新</span>
						</el-form-item>

						<el-form-item label="groupId" :label-width="formLabelWidth" prop="id" style="display: none">
							<el-input autocomplete="off" v-model="ruleForm.id"></el-input>
						</el-form-item>
					</el-form>
					<div class="dialog-footer">
						<el-button v-show="groupFormTitle == '修改分组' ? true : false" @click="DeleteBoard" round
							type="danger">删除看板</el-button>
						<el-button @click="actionGoupVisible = false" round class="cancel-group-btn">取消</el-button>
						<el-button type="primary" @click="saveChartGroup('ruleFormdata')" round
							class="save-group-btn">保存</el-button>
					</div>
				</el-dialog>
			</grid-layout>
		</el-scrollbar>
		<div id="hide" style="position: fixed;width: 100%;height: 100%;z-index: -1000;left: 0;top: 0;"></div>
	</div>
</template>

<script>
import { GridLayout, GridItem } from "vue-grid-layout"; //引入组件
import SelectChartPannel from "./SelectChartPannel.vue";
import { detectZoom, filterList } from '@/utils/tools'
import {
	apiSavechartGroup,
	apiDeleteChartGroup,
	apiSavechartsBox,
	apiDeleteCartAtgroup,
	delDoard,
	apiGetchartsList,
	investorhart
} from "@/api/ChartsSettingRequest";
import { delBoard } from "@/api/ChartsDataRequest"
import _ from "lodash"
import {setDefaultDimensions,adjustDimensions} from '@/components/fixedmin'


// 组件提前组装
let resComponents = {};
const allComponents = require.context("./chartsComponents", false, /\.vue$/);
allComponents.keys().forEach((item) => {
	let name = item;
	const currentComponents = allComponents(item);
	resComponents[name.replace(/^\.\/(.*)\.\w+$/, "$1")] =
		currentComponents.default;
});

// 求数组中的最大值
Array.prototype.max = function () {
	return Math.max.apply({}, this);
};

// 组件主体部分
export default {
	name: "VueGirdLayout",
	inject: ["reload"],
	emits: ["closeChart"],
	props: [
		"options",//分组数据
		"groupChartID",//分组id
		"itemGroupData",//名称
		// "showSelectPannel",//勾选
		// "sendResize",//高度
		"invesValue",//投资人看板
		"chartCategoryClum",//看板所有数据
		"fullScreen",//控制标题栏
		'itemGroup'
	],
	components: {
		GridLayout,
		GridItem,
		SelectChartPannel,
		...resComponents,

	},
	data() {
		return {
			ruleForm: {
				name: "",
				refresh_time: "",
				id: "",

			},
			height: '',
			scrollTop: 0,
			Time: '30',
			rules: {
				name: [
					{ required: true, message: "请输入图表分组名称", trigger: "blur" },
					{
						min: 2,
						max: 36,
						message: "长度至少为2个字符",
						trigger: "blur",
					},
				],
			},
			layout: [],
			draggable: true, //是否可拖拽
			resizable: true, //是否可更改大小
			eventLog: [],
			actionGoupVisible: false,
			groupFormTitle: "",
			formLabelWidth: 100,
			visibleStatus: true,
			groupTitle: "无分类标题",
			isReady: false,
			isShow: "",
			id: this.itemGroup[0].groupFlexible?.id || '',
			modify: false,

			value: '',//分组名称
			option: [],
			optionsTime: [
				{
					value: '0',
					label: '0'
				},
				{
					value: '30',
					label: '30',
				},
				{
					value: '60',
					label: '60',
				}
			],
			interval: 0,//间隔
			refreshTime: 0, //刷新时间
			moveEventList: {},//记录移动开始位置
			movedEventList: {}//记录移动结始位置
		};

	},
	methods: {
		scroll(e) {
			this.scrollTop = e.scrollTop
		},
		setScrollTop(value) {
			// const offsetHeight = this.$refs['parent-box'].offsetHeight
			const oldVal = this.$refs['scrollbarRef'].wrapRef.scrollHeight - this.$refs['scrollbarRef'].wrapRef.clientHeight

			if (value > 0) {

				if (this.scrollTop >= 1) {

					this.scrollTop -= 200
					this.$refs.scrollbarRef.setScrollTop(this.scrollTop)
				}
				return false
			}


			if (this.scrollTop < oldVal) {

				this.scrollTop += 200
				this.$refs.scrollbarRef.setScrollTop(this.scrollTop)
			}


		},
		// 显示图表组添加框
		addGroup() {
			this.actionGoupVisible = true;
		},
		layoutUpdated() {
			if (this.isReady) {
				this.saveChartBoxes();
			}
		},
		layoutReady() {
			this.isReady = true;
		},
		// 拖拽移动开始 高度适应
		moveChart: function (i, newX, newY, type) {
			// console.log(i, newX, newY, '拖拽移动开始');
			this.moveEventList.i = i
			this.moveEventList.x = newX
			this.moveEventList.y = newY
			this.moveEventList.chartBoxKey = type
			this.$emit("sendResize");
		},
		// 移动后的事件
		movedEvent: function (i, newX, newY, type) {
			// console.log(i, newX, newY, '移动后的事件');
			this.movedEventList.i = i
			this.movedEventList.x = newX
			this.movedEventList.y = newY
			this.movedEventList.chartBoxKey = type
		},

		// 图表列表控制
		getChartBoxList() {
			this.layout = [];
			const { group_to_board, groupFlexible, small_chart } = this.itemGroupData[0];
			small_chart.forEach(v => {
				v.chartType = 'small';
			})
			this.group_to_board = [...group_to_board, ...small_chart]
			if (groupFlexible && groupFlexible.flexible) {
				// 如果有新勾选则赋予默认位置
				const { flexible } = groupFlexible;
				const settedPostion = JSON.parse(flexible);
				// console.log('settedPostion', settedPostion)
				// console.log('group_to_board', this.group_to_board)
				const componentsFixedArr = [];
				let componentsAllArr = [];
				let xi = [];
				let aX = [];
				let aY = [];
				let name = [];
				if (Array.isArray(settedPostion)) {
					settedPostion.forEach((ele) => {
						componentsFixedArr.push(ele.chartBoxKey2);
						if (ele.chartType == 'small') {
							xi.push(ele.i + '_small');
						} else {
							xi.push(ele.i);
						}

						aX.push(ele.x);
						aY.push(ele.y);
						name.push(ele.name)

					});
					let maxY = aY.max();
					let newSelectedArr = [];
					if (settedPostion.length < this.group_to_board.length) {
						//有新勾选
						let j = 1;
						this.group_to_board.forEach((ele, i) => {
							let pageModule = ele?.charts_board?.page_module || ele?.page_module;
							if (
								!componentsFixedArr.includes(pageModule) && (!xi.includes(ele?.board_id) && !xi.includes(ele?.id + '_small'))
							) {
								let nY = maxY + 6 * j;
								newSelectedArr.push({
									x: 0,
									y: nY,
									w: 3,
									h: 6,
									i: ele?.board_id || ele.id + '_small',
									static: false,
									moved: false,
									chartBoxKey2: pageModule,
									chartBoxKey: this.getPageModule(i),
									// chartBoxKey: pageModule,
									name: ele?.name,
									value: ele?.data,
									group_to_board_obj: ele,

								});
								j++;
							} else {
								// 
							}
						});
					} else if (settedPostion.length >= this.group_to_board.length) {
						// 位置信息存有多个交付看板（这时图表定位信息的数量大于看板保存的图表数量）
						let j = 1
						this.group_to_board.forEach((ele, i) => {
							let pageModule = ele?.charts_board?.page_module || ele?.page_module
							if (
								!componentsFixedArr.includes(pageModule) && (!xi.includes(ele?.board_id) && !xi.includes(ele?.id + '_small'))
							) {
								let nY = maxY + 6 * j
								newSelectedArr.push({
									x: 0,
									y: nY,
									w: 3,
									h: 6,
									i: ele?.board_id || ele.id + '_small',
									static: false,
									moved: false,
									chartBoxKey2: pageModule,
									chartBoxKey: this.getPageModule(i),
									// chartBoxKey: pageModule,
									name: ele?.name,
									value: ele?.data,
									group_to_board_obj: ele
								})
								j++
							}
						})
					} else {
						//保存之后小看板数据变化实时更新
						settedPostion.forEach((item, i) => {
							if (item.name !== undefined) {
								this.group_to_board.forEach(v => {
									if (v.name !== undefined) {
										if (item.name === v.name) {
											item.value = v.data
										}
									}
								})
							}
						})
					}
					// 兼容多个交付看板，如：delivery_simulate、delivery_simulate&&56、delivery_simulate&&64等
					settedPostion.forEach((item, index) => {
						this.group_to_board.forEach((v, i) => {
							if (v?.charts_board && (item.chartBoxKey2 == v.charts_board.page_module)) {
								item.group_to_board_obj = v
							}
						})
					})
					// 新增看板时设置默认宽高
					// newSelectedArr.forEach(v => {
					// 	// 验证/备料 
					// 	if (v.chartBoxKey === 'deliverVerify') {
					// 		v.w = 3//初始宽度
					// 		v.h = 15//初始高度
					// 		v.minW = 3//拖拽最小宽度
					// 		v.minH = 15//拖拽最小高度
					// 	}
					// 	if (v.chartBoxKey === 'deliverDeploy') {//交付-现场部署中
					// 		v.w = 3//初始宽度
					// 		v.h = 10//初始高度
					// 		v.minW = 3//拖拽最小宽度
					// 		v.minH = 10//拖拽最小高度
					// 	} else if (v.chartBoxKey === 'deliverOperation') {//交付-运营
					// 		v.w = 6
					// 		v.h = 16
					// 		v.minW = 6//拖拽最小宽度
					// 		v.minH = 16//拖拽最小高度
					// 	} else if (v.chartBoxKey === 'demandUnaccepted') {//需求-需求发布未承接
					// 		v.w = 4
					// 		v.h = 15
					// 		v.minW = 4//拖拽最小宽度
					// 		v.minH = 15//拖拽最小高度
					// 	} else if (v.chartBoxKey === 'sale_notice') {//销售代表特别补贴公告板
					// 		v.w = 4
					// 		v.h = 9
					// 		v.minW = 4//拖拽最小宽度
					// 		v.minH = 9//拖拽最小高度
					// 	}
					// })
					setDefaultDimensions(newSelectedArr)
					componentsAllArr = [...settedPostion, ...newSelectedArr];
					// this.layout = filterList(componentsAllArr, 'i')
					this.layout = componentsAllArr;
				} else {
					setDefaultDimensions(settedPostion)
					this.layout = settedPostion;
				}
			} else {
				this.group_to_board = [...group_to_board, ...small_chart]
				if (this.group_to_board && this.group_to_board.length > 0) {

					let inboxArr = [];
					let cx = 0;
					let cy = 0;
					for (let i = 0; i < this.group_to_board.length; i++) {
						cx = cx + 3;
						if (i % 4 === 0) {
							cy = cy + 6;
							cx = 0;
						}
						inboxArr.push({
							x: cx,
							y: cy,
							w: 3,
							h: 6,
							// i: this.group_to_board[i]?.board_id,
							i: this.group_to_board[i]?.board_id || this.group_to_board[i]?.id + '_small',
							static: false,
							moved: false,
							// chartBoxKey: this.group_to_board[i]?.charts_board?.page_module,
							chartBoxKey2: this.group_to_board[i]?.charts_board?.page_module || this.group_to_board[i]?.page_module,
							chartBoxKey: this.getPageModule(i),
							name: this.group_to_board[i].name,
							value: this.group_to_board[i].data,
							group_to_board_obj: this.group_to_board[i]
						});
					}

					setDefaultDimensions(inboxArr)
					this.layout = inboxArr;
				} else {
					this.layout = [];
				}
			}
			// console.log('layout', this.layout)
		},

		// 获取图表page_module（图表对应的代码文件）
		// 兼容多个交付看板，如：delivery_simulate、delivery_simulate&&56、delivery_simulate&&64等
		// 处理delivery_simulate&&56、delivery_simulate&&64等返回delivery_simulate
		getPageModule(i) {
			if (this.group_to_board[i]?.charts_board?.page_module) {
				if (this.group_to_board[i].charts_board.page_module.split('&&')[0] === 'delivery_simulate') {
					return 'delivery_simulate'
				} else {
					return this.group_to_board[i].charts_board.page_module
				}
			} else {
				return this.group_to_board[i]?.page_module
			}
		},

		// 显示图表勾选框
		selectChartBox() {
			this.$emit("showSelectPannel", {
				status: true,
				gid: this.groupChartID,
				id: this.groupChartID
			});
		},

		// 保存图表位置等信息
		saveChartBoxes(isRefresh) {
			// 兼容笔记本，当拖拽宽度到了最小值拖拽值时(其实笔记本没到),会导致拖拽不回去，因此强制回到设置的值
			adjustDimensions(this.layout)
			// if (this.moveEventList.x !== this.movedEventList.x) {
			// 	const layoutItem = this.layout.find(item => item.chartBoxKey === this.movedEventList.chartBoxKey);

			// 	if (layoutItem) {
			// 		layoutItem.x = this.moveEventList.x; // 更新 x 值
			// 		console.log('布局已更新:', layoutItem);
			// 	}
			// }
			// const processedLayout = this.layout.map(ele => {
			// 	if (ele.group_to_board_obj && ele.group_to_board_obj.charts_board && ele.group_to_board_obj.charts_board.photo_list) {
			// 		ele.group_to_board_obj.charts_board.photo_list = ele.group_to_board_obj.charts_board.photo_list.map(photo => ({
			// 			id: photo?.id,
			// 			file_size: photo?.file_size,
			// 			file_type: photo?.file_type,
			// 			file_url: photo?.file_url,
			// 			title: photo?.title,
			// 			type: photo?.type
			// 		}));
			// 	}
			// 	return ele;
			// });

			apiSavechartsBox({
				group_id: this.groupChartID,
				flexible: JSON.stringify(this.layout),
				id: this.id
			}).then((res) => {
				if (res.data.code == 200) {
					this.$message({
						message: "页面保存成功",
						type: "success",
						duration: 1000,
					});
					this.id = res.data.data.id
				}
				if (isRefresh) this.$emit('refresh', this.groupChartID)
			});
		},

		// 关闭某图表不显示
		newCloseChart(item) {
			const { i, chartBoxKey, chartBoxKey2 } = item;
			this.$confirm("确定删除该图表吗?", "提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning",
			}).then(() => {
				this.layout.splice(
					this.layout.findIndex((layoutItem) => layoutItem.i === i),
					1
				);
				if (item?.name) {
					this.CloseChart(i)
				} else if (chartBoxKey === 'delivery_simulate') {
					this.delDeliveryChart(item)
				} else {
					apiDeleteCartAtgroup({ group_id: this.groupChartID, board_id: i }).then(
						(res) => {
							if (res.data.code == 200) {
								this.$message({
									message: "图表删除成功",
									type: "success",
									duration: 1000,
								});
								// 从图表位置配置解除此位置关系
								const processedLayout = this.layout.map(ele => {
									if (ele.group_to_board_obj && ele.group_to_board_obj.charts_board && ele.group_to_board_obj.charts_board.photo_list) {
										ele.group_to_board_obj.charts_board.photo_list = ele.group_to_board_obj.charts_board.photo_list.map(photo => ({
											id: photo.id,
											file_size: photo?.file_size,
											file_type: photo?.file_type,
											file_url: photo?.file_url,
											title: photo?.title,
											type: photo?.type
										}));
									}
									return ele;
								});
								apiSavechartsBox({
									group_id: this.groupChartID,
									flexible: processedLayout.length > 0 ? JSON.stringify(processedLayout) : "",
									// flexible:
									// 	this.layout.length > 0 ? JSON.stringify(this.layout) : "",
									id: this.itemGroupData[0]?.groupFlexible?.id,
								}).then((res) => {
									if (res.data.code == 200) {
										this.$emit('refresh', this.groupChartID)
									}
								});
							}
							this.reload({ id: this.groupChartID });
						}
					);
				}
			});
		},

		// 小看板删除
		async CloseChart(v) {
			let str = ''
			let hasSmall
			if (typeof v === 'string') {
				hasSmall = true
			} else {
				str = v.toString();
				hasSmall = str.includes('_small');
			}
			const res = await delDoard({ group_id: this.groupChartID, board_id: hasSmall ? v.replace('_small', '') : v })
			if (res.data.code == 200) {
				this.$emit('refresh', this.groupChartID)
				this.$message({
					message: "图表删除成功",
					type: "success",
					duration: 1000,
				});
			}
		},

		// 交付看板删除
		delDeliveryChart(item) {
			// console.log(item)
			if (item.isCopy) {
				// 从图表位置配置解除此位置关系
				apiSavechartsBox({
					group_id: this.groupChartID,
					flexible: this.layout.length > 0 ? JSON.stringify(this.layout) : "",
					id: this.itemGroupData[0]?.groupFlexible?.id
				}).then((res) => {
					if (res.data.code == 200) {
						this.$message({ message: "图表删除成功", type: "success", duration: 1000 })
						this.$emit('refresh', this.groupChartID)
					}
				})
			} else {
				// if (this.layout !== '') {
				//   throw new Error('123')
				// }
				apiSavechartsBox({
					group_id: this.groupChartID,
					flexible: this.layout.length > 0 ? JSON.stringify(this.layout) : "",
					id: this.itemGroupData[0]?.groupFlexible?.id,
				}).then((res) => {
					if (res.data.code == 200) {
						this.$message({ message: "图表位置保存成功", type: "success", duration: 1000 })
						apiDeleteCartAtgroup({ group_id: this.groupChartID, board_id: item.i }).then(res => {
							if (res.data.code == 200) {
								this.$message({ message: "图表删除成功", type: "success", duration: 1000 })
								this.$emit('refresh', this.groupChartID)
							}
							this.reload({ id: this.groupChartID });
						})
					}
				})
			}
		},

		// 交付看板添加按钮
		addDeliveryChart(params, layoutItem) {
			// console.log(params, layoutItem)
			const aY = []
			const aH = []
			for (const item of this.layout) {
				if (
					item.chartBoxKey === 'delivery_simulate' &&
					item.group_to_board_obj.charts_board.delivery_work_order_id == params.delivery_work_order_id
				) {
					return this.$message({ message: "已存在相同图表", type: "error", duration: 1000 })
				}
				aY.push(item.y)
			}
			for (const item of this.layout) {
				if (item.y >= aY.max()) {
					aH.push(item.h)
				}
			}
			console.log(aH)
			const obj = _.cloneDeep(layoutItem)
			obj.isCopy = true
			obj.chartBoxKey2 = 'delivery_simulate' + '&&' + params.delivery_work_order_id
			obj.i = layoutItem.isCopy ? `c_${obj.i.split('&&')[0] + '&&' + params.delivery_work_order_id}` : `c_delivery_simulate&&${params.delivery_work_order_id}`
			// Reflect.deleteProperty(obj, 'x')
			// Reflect.deleteProperty(obj, 'y')
			obj.x = 0
			obj.y = aH.max() > 6 ? aY.max() + 6 + (aH.max() - 6) : aY.max() + 6
			obj.group_to_board_obj.charts_board.delivery_work_order_id = params.delivery_work_order_id
			this.layout.push(obj)
			this.saveChartBoxes()
		},

		// 保存图表组信息
		async saveChartGroup(formName, str) {
			let valid
			let item = {}
			if (str) {
				valid = true
				item = this.option.find(fitem => fitem.name === this.value)
				item.layout = localStorage.getItem('layout')
			} else {
				valid = await this.$refs[formName].validate();
			}
			if (valid) {
				this.actionGoupVisible = false;
				this.ruleForm.refresh_time = this.Time
				const data = {
					...this.ruleForm,
					is_investor: this.invesValue,//是否经营看板1:是0:否默认否
					...item
				}
				if (str) {
					data.layout = str
				}
				apiSavechartGroup(data).then((res) => {
					if (res.data.code == 200) {
						this.$message({
							message: "页面保存成功",
							type: "success",
							duration: 1000,
						});
						if (this.modify) { //修改
							this.$emit("selectDoard", res.data.data)
							this.modify = !this.modify

						}
						let obj;
						if (this.ruleForm.id) {
							obj.id = this.ruleForm.id;
						}
						// this.reload();
					}
				}).then(() => {
					if (this.invesValue !== '') {
						investorhart().then((suc) => { //投资人看版
							suc.data.data[0].investor_group.forEach(v => {
								if (v.name == this.ruleForm.name) {

									// this.$store.commit('setCurrentPanelInvestorBoard', JSON.stringify(item))

									this.$emit("selectDoard", v)
								}
							})
						})
					} else {
						apiGetchartsList().then((suc) => { //首页和其他
							suc.data.data[0]?.user_group.forEach(v => {
								if (v.name == this.ruleForm.name) {
									this.$store.commit('setCurrentPanel', JSON.stringify(item))
                  localStorage.setItem('currentPanel', JSON.stringify(item))
									this.$emit("selectDoard", v)
								}
							})
						})
					}
				})
			}

		},

		// 图表大小调整
		sizeAutoChange(chartBoxKey) {
			this.$emit("sendResize");
			const currentChartBoxObj = this.$refs[`${chartBoxKey}`];
			this.$nextTick(() => {
				if (currentChartBoxObj && Array.isArray(currentChartBoxObj)) {
					currentChartBoxObj[0].sizechange?.();
				}
			});
		},

		// 图表大小调整(鼠标松开后触发一次)
		resizedEvent: function (chartBoxKey,) {
			this.sizeAutoChange(chartBoxKey);
			// const currentChartBoxObj = this.$refs[`${chartBoxKey}`];
			// this.$nextTick(() => {
			// 	if (currentChartBoxObj && Array.isArray(currentChartBoxObj)) {
			// 		currentChartBoxObj[0].resizedChange?.();
			// 	}
			// });

		},

		// 图表刷新
		refreshChartBoxes: function () {
			this.reload({ id: this.groupChartID });
		},

		// 图表组操作工具栏控制
		actionGroup: function (val) {
			this.$nextTick(() => {
				this.$refs["ruleFormdata"]?.clearValidate();
			});

			if (val === "add") {
				this.actionGoupVisible = true;
				this.ruleForm = {};
				this.groupFormTitle = "新增分组";
			} else if (val === "update") {
				this.actionGoupVisible = true;
				this.groupFormTitle = "修改分组";
				this.ruleForm = this.itemGroupData;
			} else if (val === "del") {
				// this.$confirm("确定删除该组吗?", "提示", {
				// 	confirmButtonText: "确定",
				// 	cancelButtonText: "取消",
				// 	type: "warning",
				// })
				// 	.then(() => {
				// 		apiDeleteChartGroup(this.itemGroupData.id).then((res) => {
				// 			if (res?.data?.code == 200) {
				// 				this.$message({
				// 					message: "删除成功",
				// 					type: "success",
				// 					duration: 1000,
				// 				});
				// 				this.reload();
				// 			} else {
				// 				this.$message({
				// 					message: "该分组不能删除",
				// 					type: "warning",
				// 					duration: 1000,
				// 				});
				// 			}
				// 		});
				// 	})
				// 	.catch(() => {
				// 		this.$message({
				// 			type: "info",
				// 			message: "已取消删除",
				// 			duration: 1000,
				// 		});
				// 	});
			} else {
				this.actionGoupVisible = true;
				this.ruleForm = {};
				this.groupFormTitle = "新增分组";
			}
		},
		// 选择刷新时间
		elOptionTiem(v) {
			this.Time = v
		},

		// 选择看板
		selectChange(e) {
			const item = this.option.find(f => f.name === e)
			// this.selectChangeItem=item
			// console.log("selectChange-item", item);
			if (this.$store.state.invesValue !== '') {
				this.$store.commit('setCurrentPanelInvestorBoard', JSON.stringify(item))
			} else {
				this.$store.commit('setCurrentPanel', JSON.stringify(item))
        localStorage.setItem('currentPanel', JSON.stringify(item))
			}
			this.$emit("selectDoard", item)
		},
		// 编辑看板
		handleOptionItem(item) {
			this.modify = !this.modify
			this.actionGoupVisible = true;
			this.groupFormTitle = "修改分组";
			this.Time = item.refresh_time
			this.ruleForm.name = item.value;
			this.ruleForm.id = item.id
			// this.ruleForm.refresh_time = item.refresh_time
		},
		// 删除看板
		DeleteBoard() {
			this.$confirm("确定删除该组吗?", "提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning",
			})
				.then(() => {
					apiDeleteChartGroup(this.ruleForm.id).then((res) => {
						if (res?.data?.code == 200) {
							if (Number(JSON.parse(this.$store.state.currentPanel).id) == this.ruleForm.id) {
                this.$store.commit('setCurrentPanel', '')
                localStorage.setItem('currentPanel', '')
							}
							this.$message({
								message: "删除成功",
								type: "success",
								duration: 1000,
							});
							this.actionGoupVisible = false

							if (this.chartCategoryClum[0].id == this.ruleForm.id) { //删除当前看板并跳转到默认看板
								// 投资人看板还是首页与其他
								this.$emit("selectDoard", this.invesValue !== '' ? this.chartCategoryClum[0].investor_group[0].id : this.chartCategoryClum[0].user_group[0])
							} else {
								this.reload();
							}
						} else {
							this.$message({
								message: "该分组不能删除",
								type: "warning",
								duration: 1000,
							});
						}
					})
				})
				.catch(() => {
					this.$message({
						type: "info",
						message: "已取消删除",
						duration: 1000,
					});
				});
		},
		// 看板添加
		handleAdd() {
			this.actionGoupVisible = true;
			this.ruleForm = {};
			this.groupFormTitle = "新增分组";
			if (this.Time == '0') {
				this.Time = '30'
			}
		}
	},
	watch: {
		fullScreen: {
			immediate: true, //初始化时让handler调用一下
			deep: true,
			//handler什么时候调用？当isHot发生改变时。（一个参数就是newValue）
			handler(newValue, oldValue) {

				if (newValue) {
					// console.log("不是全屏");
					if (window.$wujie) {
						this.height = `${parseInt(window.$wujie.props.data.height) - 50}px`
					} else {

						this.$nextTick(() => {
							// this.height = `${parseInt(window.$wujie.props.data.height) - 50}px`
							this.height = document.getElementById('hide').clientHeight - 50
						})
					}

				} else {
					// console.log("是全屏");
					this.$nextTick(()=>{
						this.height = window.screen.height - 50
					})
					

				}
			}
		}

	},

	mounted() {

		if (window.$wujie) {

			this.height = `${parseInt(window.$wujie.props.data.height) - 50}px`

		}

		this.getChartBoxList();//this.groupChartID		
		this.value = this.itemGroupData[0].name;
		this.option = this.options
		detectZoom()
		// this.groupTitle = this.itemGroupData;//原分组名称
		this.isShow = sessionStorage.getItem("isShow");

		window.onresize = () => {
			return (() => {
				// this.resizedEvent("customer_status_profile");
			})();
		};
		this.refreshTime = sessionStorage.getItem('refreshTime')
		if (this.refreshTime != 0) {
			this.interval = setInterval(() => {
				let id = { id: this.groupChartID }
				this.layout = [];
				this.$emit("selectDoard", id)
			}, this.refreshTime * 60000)
		}

	},
	destoryed() {
		this.interval && clearInterval(this.interval)
	}
};
</script>

<style lang="scss" scoped>
.vue-grid-layout {
	padding-bottom: 8px;
	min-height: 200px;
}

.vue-grid-item:not(.vue-grid-placeholder) {
	background: rgba(255, 255, 255, 0.08);
	border-radius: 10px;
	overflow: hidden;
}

.right-icon {
	display: flex;
	align-items: center;
}

.right-icon img {
	display: block;
	margin-right: 15px;
	cursor: pointer;
}

.top-tool-group {
	float: left;
	cursor: pointer;
	margin-right: 12px;
	background: url("../assets/tools-chart.png");
	background-size: 19px 19px;
	background-position: 100% 100%;
	height: 20px;
	width: 20px;
	background-repeat: no-repeat;
}

.page-title {
	font-size: 24px;
	color: #999;
}

.chart-setting {
	border-radius: 8px !important;
}

.input-ctrol {
	width: 400px !important;
}

.chart-catgory {
	height: 50px;
	line-height: 40px;
	display: flex;
	border-radius: 8px;
	background-color: #143230;
	// margin: 0 8px;
}

// 不全屏
// .catgory{
// 	position: fixed;
// 	margin: auto;
// 	width: calc(100% - 216px);
// 	top: 95px;
// 	right: 8px;
// 	z-index: 100;
// }
// // 全屏
// .catgorys{
// 	position: fixed;
// 	margin: auto;
// 	width: calc(100% - 16px);
// 	top:0;
// 	right: 8px;
// 	z-index: 100;
// }
:deep(.vue-grid-layout) {
	// margin-top: 50px;
}

.save-group-btn {
	background-color: rgb(0, 95, 89) !important;
}

.cancel-group-btn {
	border: 1px solid rgb(0, 95, 89) !important;
	color: rgb(0, 95, 89) !important;
}

.group-title {
	color: #ccc;
	/* margin-top: 4px; */
	font-size: 20px;
	font-weight: 500;
	flex: 1;
}

.el-dropdown-link:focus {
	outline: 0 !important;
}

.el-form-item__label {
	color: rgb(0, 95, 89) !important;
}

.no-chart-desc {
	color: #ccc;
	position: absolute;
	top: calc(50% - 10px);
	left: calc(50% - 120px);
}

.vue-resizable-handle {
	background-size: 10px 10px !important;
	bottom: 0;
	right: 0;
}
:deep(.vue-resizable-handle){
	background:url(../assets/hook.png);
	background-position: 100% 100%;
	background-repeat: no-repeat;
	background-origin: content-box;
	-webkit-box-sizing: border-box;
}

.vue-draggable-handle {
	position: absolute;
	width: 20px;
	height: 20px;
	top: 0;
	padding: 0 8px 8px 6px;
	background-origin: content-box;
	color: #fff;
	box-sizing: border-box;
	cursor: pointer;
	z-index: 50;
	font-size: 20px;


}

.dialog-footer {
	text-align: right;
}

/* 标题 */
.autoWidth-select {
	margin: auto;
	font-weight: 500;
	font-size: 22px;

	:deep(.el-select-dropdown__item.is-disabled) {
		cursor: pointer;
	}

	.add {
		line-height: 40px;

		svg {
			vertical-align: middle;
			margin-right: 5px;

		}

		span {
			color: rgb(0, 41, 37);
		}
	}
}

:deep(.el-date-table td.today .el-date-table-cell__text) {
	color: #005f59
}

:deep(.el-date-table td.current:not(.disabled) .el-date-table-cell__text) {
	color: #fff;
}

:deep(.el-date-editor .el-icon) {
	display: none;
}

:deep(.el-date-picker) {
	--el-datepicker-hover-text-color: #193e3a;
	--el-datepicker-active-color: #005f59;
}

:deep(.el-date-editor) {
	--el-input-bg-color: #193e3a;
	--el-date-editor-daterange-width: 180px
}

:deep(.el-date-editor) {
	background-color: transparent;
	--el-input-focus-border-color: transparent;
	--el-input-border-color: transparent;
	--el-input-text-color: #fff;
	--el-date-editor-width: 120px;
	--el-input-hover-border-color: transparent;
}

:deep(.el-input__inner) {
	font-size: 18px;
	cursor: pointer;
	margin-top: 1px;
}

.c_name {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	padding-right: 10px;
}

.align_items_center {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

:deep(.el-select) {
	min-width: 100px;
	--el-select-input-focus-border-color: transparent;
	--el-select-border-color-hover: transparent;

	.el-input {
		--el-input-bg-color: transparent;
		--el-input-hover-border: transparent;
		--el-input-text-color: #fff;
	}

	.el-input .el-select__caret {
		color: #fff;
	}

	.el-input__inner {
		text-align: center;
		font-size: 22px;
	}
}

:deep(.chart-catgory .el-input__wrapper) {
	box-shadow: 0 0 0 1px transparent inset;
}

:deep(.el-select-dropdown__item) {
	color: rgb(0, 41, 37);
}

:deep(.el-select-dropdown__item.selected) {
	color: rgb(249, 183, 7);
}

:deep(.autoWidth-select) {
	text-align: start;

	.el-input__prefix {
		position: relative;
		left: 0px;
		box-sizing: border-box;
		padding: 0 30px;
		visibility: hidden;
	}

	input {
		position: absolute;

	}
}

// .el-input
.optionsTime {
	:deep(.select-trigger) {
		width: 100px;
		height: 30px;
		--el-select-input-focus-border-color: #ccc;
	}

	:deep(.el-input--suffix) {
		--el-select-border-color-hover: #ccc;
	}

	:deep(.el-input) {
		height: 30px;
		--el-input-text-color: var(--el-input-text-color, var(--el-text-color-regular));
		--el-input-hover-border: #ccc;
	}

	:deep(.el-input:hover) {
		height: 30px;

	}
}
</style>
