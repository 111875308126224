<template>
	<div ref="parent-box" v-if="isRouterAlive">

		<SelectChartPannel :visible="currentVisible" v-if="currentVisible" @showSelectPannel="showSelectPannel"
			:groupChartID="openGroupId" :close-on-press-escape="false" @refresh="refresh" :selecte="selecte"
			:invesValue="invesValue" :close-on-click-modal="false"></SelectChartPannel>

		<div ref="wrapRef" class="wrapRef" style="height:100%">

			<!-- <div style="position: absolute;right:50px;top: 50px;z-index: 100;">{{height}}</div> -->
			<VueGirdLayout :key="vueGirdLayoutKey" v-if="namevalue" :groupChartID="publicStore" :itemGroupData="namevalue" :itemGroup="namevalue"
				:selectedGraph="selectedGraph" :invesValue="invesValue" :options="options"
				@showSelectPannel="showSelectPannel" @sendResize="sendResizePageParameter" @refresh="refresh"
				@selectDoard="selectDoard" :fullScreen="fullScreen" :chartCategoryClum="chartCategoryClum" ref="childRef" />
		</div>
		<!-- <el-scrollbar :height="height" ref="scrollbarRef" @scroll="scroll">
		</el-scrollbar> -->
		<!-- <template v-for="item in chartCategoryClum">
			<div :key="item.id" v-if="item.isRefresh">
				<VueGirdLayout :key="item.id" :groupChartID="item.id" :itemGroupData="item" :invesValue="invesValue"  @showSelectPannel="showSelectPannel"
					@sendResize="sendResizePageParameter"  ref="childRef"  />
			</div>
		</template> -->

		<!-- 废弃 -->
		<!-- <div class="full-screen-float">
			<div class="circle" :style="{ borderRadius: this.addValue == '0' ? '10px 0px 0px 10px' : '10px 0px 0px 0px' }"
				@click="screen">
				全屏
			</div>
			<div>
				<div v-if="this.addValue == '1'" class="circle fillets" @click="add">添加</div>
				<div v-if="this.invesValue == ''" class="circle fillets" @click="add">添加</div>
			</div>
		</div> -->
		<QuickMenu v-if="options.length > 0" @handleScreen="screen" :fullScreen="fullScreen" :addValue="addValue"
			:invesValue="invesValue" @handleBtnClick="add" @setScrollTop="setScrollTop" @set-layout="setLayout"></QuickMenu>

	</div>
</template>

<script>

import VueGirdLayout from "./components/NewGirdLayout.vue";
import QuickMenu from "./components/QuickMenu.vue";
import SelectChartPannel from "./components/SelectChartPannel.vue";
import { apiGetchartsList, investorhart } from "@/api/ChartsSettingRequest";
import { ElNotification } from 'element-plus'
import devicePixelRatio from './components/devicePixelRatio'
export default {
	name: "App",
	components: {
		VueGirdLayout,
		SelectChartPannel,
		QuickMenu,
	},
	provide() {
		return {
			reload: this.reload,
		};
	},
	data() {
		return {
			isRouterAlive: true,
			currentVisible: false,
			route: "",
			type: 1,
			invesValue: '',
			addValue: '',
			selecte: '',

			chartCategoryClum: [],
			options: [],//分组数据
			namevalue: '',//分组名称
			publicStore: '',//分组id
			id: '',
			fullScreen: true,
			scrollTop: 0,
			// height: '',
			// isclick:true,
			// islongClick:false,
			// timeOut:'',
			// timeOutEvent:0
      vueGirdLayoutKey: 0
		};
	},
	methods: {


		setLayout(str) {

			this.$refs.childRef.saveChartGroup('ruleFormdata', str)

		},
		setScrollTop(value) {

			this.$refs.childRef.setScrollTop(value)



		},
		async init() {
			// localStorage.setItem('test','7897979798')
			let res = ''

			this.$store.commit('setInvesValue', this.invesValue)
			if (this.invesValue !== '') {
				if (this.$store.state.currentPanelInvestorBoard) {
					this.id = JSON.parse(this.$store.state.currentPanelInvestorBoard).id
				}
				res = await investorhart({ group_id: this.id }) //投资人看版
				// console.log(res,'投资人看版');
				if (this.$store.state.currentPanelInvestorBoard) {
					const data = JSON.parse(this.$store.state.currentPanelInvestorBoard)
					const currentPanel = res.data?.data[0]?.investor_group.find(item => item.id === data.id)
					if (currentPanel) {

						// this.$store.commit('setCurrentPanelInvestorBoard', JSON.stringify(currentPanel))
					} else {
						// this.$store.commit('setCurrentPanelInvestorBoard', JSON.stringify(res.data.data[0].investor_group[0]))

					}
				}
				// const currentPanel = res.data.data[0].investor_group.find(item => item.id === this.id)
				// if(this.$store.state.currentPanelInvestorBoard){

				// }

			} else {
				// this.$store.state.currentPanel
				if (localStorage.getItem('currentPanel')) {
					this.id = JSON.parse(localStorage.getItem('currentPanel')).id
				}
				res = await apiGetchartsList({ group_id: this.id });//首页和其他
				const currentPanel = res.data?.data[0]?.user_group.find(item => item.id === this.id)
				if (currentPanel) {
					// this.$store.commit('setCurrentPanel', JSON.stringify(currentPanel))
				} else {
					// this.$store.commit('setCurrentPanel', JSON.stringify(res.data.data[0].user_group[0]))

				}
			}



			// console.log(123)
			// console.log("*-*-*-更新？？？？--*-*-*-*-",res.data.data[0].user_group);
			if (res.data.code == 200) {
				this.options = []
				this.chartCategoryClum = res.data.data
				this.namevalue = res.data.data //分组名称
				this.publicStore = res.data.data[0].id //分组id
				localStorage.setItem('layout', res.data.data[0].layout)
				// console.log(res, '数据', this.chartCategoryClum);
				res.data.data.forEach(item => {
					item.isRefresh = true
				})
				if (this.invesValue !== '') {//投资人看版
					res.data.data[0]?.investor_group.forEach(item => {
						this.options.push({
							...item,
							value: item.name,
							label: item.id,
							disabled: false,
						})
						if (item.name == res.data.data[0].name) {
							sessionStorage.setItem('refreshTime', item.refresh_time)
						}
					})
				} else {//首页和其他
					res.data.data[0]?.user_group.forEach(item => {
						this.options.push({
							...item,
							value: item.name,
							label: item.id,
							disabled: false,
						})
						if (item.name == res.data.data[0].name) {
							sessionStorage.setItem('refreshTime', item.refresh_time)
						}
					})
				}

				// this.$forceUpdate()
				// console.log(res.data.data, 'res.data.data');
			} else {

				ElNotification({
					title: '图表获取异常:/api/admin/bulletin/chart-list',
					dangerouslyUseHTMLString: true,
					message: `<strong>code=${res.response.data.code}</strong>
					<h4>message=${res.response.data.message}</h4>
					<h3 style='color:red'>token:${localStorage.getItem("nnd-token")}</h3>`,
					duration: 0,
				})
				// ElMessage.error(`code=${res.response.data.code}message=${res.response.data.message}`);
			}
			this.options.push({
				value: 'add',
				label: 'add',
				disabled: true
			})
		},
		// 显示选择图表格
		showSelectPannel(e) {
			this.currentVisible = e.status;
			this.openGroupId = e.gid;
			this.selecte = e.id
		},
		// 选中的分组
		selectDoard(e) {
			this.id = e.id
			this.namevalue = ''
			this.init()
      this.vueGirdLayoutKey++
		},
		// 添加刷新
		refresh(v) {
			this.id = v
			this.namevalue = ''
			this.init()
			// this.offsetHeight = this.$refs['parent-box'].offsetHeight + 'px'
		},
		sendResizePageParameter() {
			window.parent.postMessage(
				{
					chartHeight: this.$refs["parent-box"].scrollHeight + 16,
					type: "chart",
				},
				"*"
			);
		},
		async reload(data) {
			// this.offsetHeight = this.$refs['parent-box'].offsetHeight + 'px'
			this.currentVisible = false
			await this.init()
			if (data) {
				// console.log(data,'局部刷新');
				const index = this.chartCategoryClum.findIndex(item => item.id === data.id)
				this.chartCategoryClum[index].isRefresh = false
				this.$nextTick(function () {
					this.chartCategoryClum[index].isRefresh = true
				})
			} else {
				// console.log('全局刷新');
				this.isRouterAlive = false
				this.$nextTick(function () {
					this.isRouterAlive = true
				})
			}

		},
		add() {//添加
			//  调用子组件的方法
			let child = this.$refs.childRef
			// child[0].selectChartBox()
			child.selectChartBox()

		},
		screen() {//全屏
			
			if (this.invesValue !== '') {//投资人看版
				window.$wujie.props.method.propsCreen()
			} else {//首页
				window.$wujie.props.method.propsCreen()
			}
			this.fullScreen = !this.fullScreen
		}

	},
	// mounted(){
	// 	window.addEventListener('message', (e)=>{
	// 		console.log(e);
	// 		localStorage.clear()
	// 	}, false)
	// },
	async created() {
		// new devicePixelRatio().init() 
		let getUrl = window.location.href;
		this.route = window.location.href;
		let queryInfo = getUrl.split("?")[1];
		let getQuery = new URLSearchParams("?" + queryInfo);
		let token

		if (getQuery.get("inves")) {//判断是否为空
			this.invesValue = getQuery.get("inves");//经营看版 请数据

		}

		let data = getQuery.get("add")
		sessionStorage.removeItem('isShow');
		if (data !== null) {
			if (data.indexOf('isEdit') != -1) {//查看功能1 管理0
				sessionStorage.setItem("isShow", '0')
			} else {
				sessionStorage.setItem("isShow", '1')
			}
			this.addValue = data.indexOf('investorAdd') != -1 ? '1' : '0' //添加
		}

		console.log(window.$wujie);
		if (window.$wujie) {
			token = window.$wujie.props.data.token


		} else {
			token = getQuery.get("token");
		}

		if (token == "" || token == undefined || token == null) {
			localStorage.clear()
			console.log("No token");
		} else {
			if (localStorage.getItem('nnd-token') !== token) {
				
				console.log("token 切换登录");
				this.$store.commit('setCurrentPanelInvestorBoard', null)
				this.$store.commit('setCurrentPanel', null)
				localStorage.clear()
			}
			localStorage.setItem("nnd-token", token);

			this.init()
		}
	},
	// beforeUnmount() {
	// 	// localStorage.clear()
	//   console.log("--beforeUnmount");
	// },
	// unmounted() {
	// 	// localStorage.clear()
	//   console.log("--unmounted");
	// }

};
</script>

<style lang="scss">
#app {
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
	// padding-bottom: 50px;
	width: 100%;
	height: 100%;
}

body {
	// background-color: red;
	// width: 100vw;
	//   height: 100vh;

}

.vue-grid-layout {
	background-color: #00211e;
}

body {
	position: relative;
}

.sort {
	display: block;
	width: 18px;
	height: 18px;
	position: absolute;
	right: 30px;
	top: 3px;
	cursor: pointer;
}

.vue-grid-layout .el-loading-mask {
	background-color: rgba(20, 50, 48, .5) !important
}

.el-loading-spinner .path {
	stroke: #FFC824 !important
}

.tips::after {
	display: block;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	content: "暂无数据";
}

.chartCustomRingValue_center {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}

.el-button--primary {
	--el-button-border-color: transparent !important;
	--el-button-hover-border-color: transparent !important;
}

body {
	background: #00211e;
	width: 100%;
}

.full-screen-float {
	position: fixed;
	z-index: 9;
	right: 0;
	top: 43%;
	border-top-left-radius: 20px;
	border-bottom-left-radius: 20px;
	color: #000;
	display: flex;
	flex-direction: column;

	.circle {
		width: 55px;
		height: 30px;
		line-height: 30px;
		background-color: rgba(249, 183, 7, .7);
		justify-content: space-around;
		font-size: 14px;
		cursor: default;
	}

	.fillets {
		border-radius: 0px 0px 0px 10px;
	}
}

.vue-grid-layout {
	position: relative;
}

.model {

	// background-color: rgba(206, 174, 106, 0.925);
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
}

.vue-chart-close,
.vue-resizable-handle {
	z-index: 50;
}

// html,body,div{
// 	-webkit-touch-callout:none;
//       -webkit-user-select:none;
//       -khtml-user-select:none;
//       -moz-user-select:none;
//       -ms-user-select:none;
//       user-select:none;
// }
.strip_wrap .strip_item .strip :nth-child(1),
.strip_wrap .strip_item .strip :nth-child(1) span {
	border-radius: 4px;
}
</style>
