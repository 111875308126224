<!-- 交付看板 -->
<template>
  <div v-loading="loading" @click="resizedChange" ref="MyChartDom" class="my_tasks">
    <div class="my_tasks_title">
      <!-- <span class="gw">{{data.analog_work_station_name}}</span> -->
      {{data.title}}
      <el-button style="margin-left: 15px" round color="#F9B707" @click="add"><el-icon><DocumentCopy /></el-icon>复制为新看板</el-button>
    </div>
    <div class="my_tasks_item_wrap" :style="{ left: num + 'px' }">
      <div class="base-info">
        <el-form label-width="150px">
          <el-row>
            <el-col :span="12">
              <el-form-item label="模拟工位号：">
                <span class="value">{{data.analog_work_station_name}}</span>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="工单编号：">
                <span class="value">{{data.work_order_no}}</span>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="工单开始时间：">
                <span class="value">{{data.plan_start_time}}</span>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="工单结束时间：">
                <span class="value">{{data.plan_end_time}}</span>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="客户名称：">
                <span class="value">{{data.customer_short_name}}</span>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="产品名称：">
                <span class="value">{{data.product_name}}</span>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="关联方案：">
                <span class="value">{{data.work_station_scheme_no}}</span>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="是否延期：">
                <span v-if="data.abnormal_status === 2" class="value" style="color: red">{{'是'}}</span>
                <span v-else class="value">{{'否'}}</span>
              </el-form-item>
            </el-col>
            <el-col :span="12" v-for="(node_degree, index) in data.node_degree" :key="index">
              <el-form-item :label="node_degree.name + '：'">
                <div class="form-progress">
                  <!-- <span style="color: #F9B707; text-align: center">{{node_degree.degree}}%</span> -->
                  <el-progress style="width: 90%" :text-inside="true" :stroke-width="20" :percentage="node_degree.degree" status="warning"/>
                </div>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div class="main-user-info">
        <div class="main-user-info-title">主要人员信息</div>
        <div class="info-content">
          <el-row class="info-content-row">
            <el-col :span="8">
              <div class="box">
                <div>
                  <el-image class="box-pic" :src="'https://' + data.delivery_manager.head_img">
                    <template #error>
                      <div class="image-slot">
                        <div>
                          空
                        </div>
                      </div>
                    </template>
                  </el-image>
                </div>
                <div style="margin-left: 10px">
                  <div class="box-label">姓名：{{data.delivery_manager.nickname}}</div>
                  <div class="box-label">职务：{{'交付总监'}}</div>
                  <div class="box-label">电话：{{data.delivery_manager.mobile}}</div>
                </div>
              </div>
            </el-col>
            <el-col :span="8">
              <div class="box">
                <div>
                  <el-image class="box-pic" :src="'https://' + data.scheme_engineer.head_img">
                    <template #error>
                      <div class="image-slot">
                        <div>
                          空
                        </div>
                      </div>
                    </template>
                  </el-image>
                </div>
                <div style="margin-left: 10px">
                  <div class="box-label">姓名：{{data.scheme_engineer.nickname}}</div>
                  <div class="box-label">职务：{{'方案工程师'}}</div>
                  <div class="box-label">电话：{{data.scheme_engineer.mobile}}</div>
                </div>
              </div>
            </el-col>
            <el-col :span="8">
              <div class="box">
                <div>
                  <el-image class="box-pic" :src="'https://' + data.electrical_engineer.head_img">
                    <template #error>
                      <div class="image-slot">
                        <div>
                          空
                        </div>
                      </div>
                    </template>
                  </el-image>
                </div>
                <div style="margin-left: 10px">
                  <div class="box-label">姓名：{{data.electrical_engineer.nickname}}</div>
                  <div class="box-label">职务：{{'交付工程师'}}</div>
                  <div class="box-label">电话：{{data.electrical_engineer.mobile}}</div>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
      <div class="task-user-info">
        <div class="info-content">
          <el-row class="info-content-row" v-for="(item, index) in data.task" :key="index">
            <el-col :span="4"></el-col>
            <el-col :span="10">
              <div class="box">
                <el-image class="box-pic" :src="'https://' + item.user_info.head_img">
                  <template #error>
                    <div class="image-slot">
                      <div>
                        空
                      </div>
                    </div>
                  </template>
                </el-image>
                <div style="margin-left: 20px">
                  <div class="box-label">姓名：{{item.user_info.nickname}}</div>
                  <div class="box-label">职务：{{item.user_info.position}}</div>
                  <div class="box-label">电话：{{item.user_info.mobile}}</div>
                </div>
              </div>
            </el-col>
            <el-col :span="10">
              <div class="box">
                <div>
                  <div class="box-label">任务：{{item.name}}</div>
                  <div class="box-label">状态：{{item.status_text}}</div>
                  <div class="box-label">进度：{{item.progress_rate + '%'}}</div>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
  </div>
  <span v-show="isShow !== '1'" class="vue-chart-close" @click="handleCloseChart"> </span>
  <el-dialog
    v-model="visible"
    title="交付看板"
    width="500"
  >
    <div>
      <el-select
        v-model="deliveryWorkOrderId"
        placeholder="选择工单"
        style="width: 80%"
      >
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        />
      </el-select>
    </div>
    <template #footer>
      <div class="dialog-footer">
        <el-button round color="#F9B707" @click="confirm">确定</el-button>
        <el-button round color="#F9B707" @click="cannel">取消</el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script>
import { workOrderSelectMap, deliverySimulateDegree, saveBoard } from "@/api/ChartsDataRequest"

export default {
  name: "delivery_simulate",
  props: {
    group_to_board_obj: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  emits: ["closeChart"],
  data() {
    return {
      num: 0,
      loading: true,
      isShow: '',
      w: '100%',
      visible: false,
      options: [],
      deliveryWorkOrderId: '',
      data: {
        title: '',
        work_order_no: '',
        plan_start_time: '',
        plan_end_time: '',
        progress_rate: '',
        customer_short_name: '',
        product_name: '',
        work_station_scheme_no: '',
        abnormal_status: '',
        delivery_manager: {
          head_img: '',
          mobile: '',
          nickname: ''
        },
        scheme_engineer: {
          head_img: '',
          mobile: '',
          nickname: ''
        },
        electrical_engineer: {
          head_img: '',
          mobile: '',
          nickname: ''
        }
      }
    }
  },
  methods: {
    // 图表关闭
    handleCloseChart() {
      this.$emit("closeChart");
    },
    // 图表创建
    createChart() {
      // console.log(this.group_to_board_obj)
      this.loading = false
      workOrderSelectMap().then(res => {
        this.options = res.data.data
      })
      deliverySimulateDegree({delivery_work_order_id: this?.group_to_board_obj?.charts_board?.delivery_work_order_id}).then(res => {
        this.data = res.data.data
      })
    },
    // 跳转
    goUrl(row,value) {
      if(parseInt(value)!==0) window.$wujie.props.method.propsMethod(row)
    },
    // 添加新看板
    add() {
      this.visible = true
    },
    // 删除看板
    async del() {
      this.$emit('deliveryDelChart')
    },
    // 弹窗确认
    confirm() {
      if (this.deliveryWorkOrderId) {
        const params = {
          delivery_work_order_id: this.deliveryWorkOrderId,
          name: '交付-模拟阶段看板',
          type: this.group_to_board_obj.charts_board.type,
          page_module: 'delivery_simulate',
          preview: this.group_to_board_obj.charts_board.preview,
          status: this.group_to_board_obj.charts_board.status,
          is_customer_board: this.group_to_board_obj.charts_board.is_customer_board,
          remark: this.group_to_board_obj.charts_board.remark
        }
        this.$emit('addDeliveryChart', params)
        this.deliveryWorkOrderId = ''
        this.visible = false
      } else {
        this.$message({ message: "请先选择工单", type: "warning", duration: 1000 })
      }
    },
    // 弹窗取消
    cannel() {
      this.deliveryWorkOrderId = ''
      this.visible = false
    }
  },
  mounted() {
    this.createChart()
    this.isShow = sessionStorage.getItem("isShow")
  }
}
</script>

<style lang="scss" scoped>
:deep( .el-input__wrapper) {
  border: 1px solid #83bdb8 !important;
}
:deep( .el-input__inner) {
  color: #143230 !important;
  text-align: left !important;
  font-size: 16px !important;
}
:deep( .el-select__caret) {
  color: #143230 !important;
}
.chart-drobot-title {
  color: #fff;
  font-size: 18px;
  padding-top: 8px;
}

.my_tasks {
  overflow: auto;
  width: 100%;
  height: 100%;

  .arrow {
    position: absolute;
    left: 0;
    height: 80%;
    bottom: 0;
    display: flex;
    align-items: center;
    z-index: 10;
    cursor: pointer;
    background-color: #143230;
  }

  .arrow-right {
    left: auto;
    right: 0;
  }

  // 省略
  .omit {
    color: #fff;
    font-size: 25px;
    position: absolute;
    left: 76%;
    height: 80%;
    bottom: 0;
    display: flex;
    align-items: center;
    z-index: 10;
    cursor: pointer;
  }

  .my_tasks_title {
    font-size: 20px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    height:68px;
    background-color: rgba(255, 255, 255, 0.08);
    color: #fff;
    .gw {
      margin-right: 50px;
      font-size: 15px;
      color: #f9b707;
    }
  }

  .my_tasks_item_wrap {
    width: v-bind(w);
    height: 80%;
    text-align: left;
    align-items: center;
    transition: all 0.1s ease-in-out;
    position: relative;
    .item-row {
      width: 360px;
      height: 100%;
      margin-left: 20px;
      .item-box {
        width: 100%;
        height: 90%;
        display: flex;
        flex-direction: column;
        align-items: center;
        border: 1px solid #ccc;
        border-radius: 15px;
        font-size: 1.4rem;
        color: #fff;
        margin-top: 10px;
        .children {
          display: block;
          .content {
            margin-top: 20px;
            span {
              color: #f9b707;
              cursor: pointer;
            }
          }
        }
      }
    }
    .base-info {
      :deep(.el-form-item__label) {
        color: #fff;
      }
      .form-progress {
        :deep(.el-progress-bar__outer) {
          border: 1px solid #f9b707;
          background-color: transparent;
        }
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-items: center;
      }
      width: calc(100% - 30px);
      padding: 15px;
      .value {
        color: #fff;
      }
    }
    .main-user-info {
      width: calc(100% - 20px);
      padding: 10px;
      display: flex;
      flex-direction: column;
      justify-items: center;
      .main-user-info-title {
        width: calc(100% - 10px);
        padding: 5px;
        color: #fff;
        text-align: center;
        font-weight: bold;
        border-top: 1px solid rgba($color: #f0eded, $alpha: .4);
      }
      .info-content {
        padding: 10px;
        .info-content-row {
          margin-top: 10px;
          .box {
            display: flex;
            align-items: center;
            justify-content: center;
            .box-pic {
              width: 60px;
              height: 60px;
              border-radius: 5px;
              .image-slot {
                width: 100%;
                height: 100%;
                background-color: #f0eded;
                border-radius: 5px;
                display: flex;
                justify-content: center;
                align-items: center;
              }
            }
            .box-label {
              color: #fff;
              font-size: 14px;
            }
            .box-label:nth-child(n+2) {
              margin-top: 5px;
            }
          }
        }
        .info-content-row:nth-child(n+2) {
          margin-top: 20px;
        }
      }
    }
    .task-user-info {
      width: calc(100% - 20px);
      padding: 10px;
      display: flex;
      flex-direction: column;
      justify-items: center;
      .main-user-info-title {
        width: calc(100% - 30px);
        padding: 5px;
        color: #fff;
        text-align: center;
        font-weight: bold;
        border-top: 1px solid rgba($color: #f0eded, $alpha: .4);
      }
      .info-content {
        padding: 10px;
        .info-content-row {
          margin-top: 10px;
          padding-top: 20px;
          border-top: 1px solid rgba($color: #f0eded, $alpha: .4);
          .box {
            display: flex;
            align-items: center;
            .box-pic {
              width: 60px;
              height: 60px;
              border-radius: 5px;
              .image-slot {
                width: 100%;
                height: 100%;
                background-color: #f0eded;
                border-radius: 5px;
                display: flex;
                justify-content: center;
                align-items: center;
              }
            }
            .box-label {
              color: #fff;
              font-size: 14px;
            }
            .box-label:nth-child(n+2) {
              margin-top: 5px;
            }
          }
        }
        .info-content-row:nth-child(n+2) {
          margin-top: 20px;
        }
      }
    }
  }
}

.dialog-footer {
  display: flex;
  justify-content: center;
}
</style>
