<!--累计意向客户/已部署客户/工位数量-->
<template>
	<div class="tips" style="color: #fff;" v-if="option.yData==0" ></div>
	<div
		@click="sizechange"
		ref="MyChartDom"
		v-loading="loading"
		:style="{ width: '100%', height: '100%', overflow: 'hidden' }"
	></div>
	<span v-show="isShow!=='1'" class="vue-chart-close" @click="handleCloseChart"> </span>
</template>

<script>
import * as echarts from "echarts";
import { apiGetCustomerAndDeployed } from "@/api/ChartsDataRequest";

export default {
	name: "customer_status_profile",
	props: ["xref", "type", "chartData"],
	emits: ["closeChart"],
	data() {
		return {
			option:'',
			loading: true,
			isShow:''
		};
	},
	methods: {
		// 图表关闭
		handleCloseChart() {
			this.$emit("closeChart");
		},

		// 图表创建
		createChart() {
			this.ChartDom = this.$refs.MyChartDom;
			this.MyChart = echarts.init(this.ChartDom, null);
			apiGetCustomerAndDeployed().then((res) => {
				if (res?.status === 200) {
					const currentData = res?.data?.data;
					this.option = currentData
					const tData = currentData.xData.reduce(
						(prev, cur) => prev.concat(cur),
						[]
					);
					let xData1 = []
					let xData2 = []
					let xData3 = []
					if(currentData.yData.length==0){
						xData1 = []
						xData2 = []
						xData3 = []
					}else{
							xData1 = currentData.yData.find(
							(item) => item.name === "工位数量"
						).data;
							xData2 = currentData.yData.find(
							(item) => item.name === "已部署客户"
						).data;
							xData3 = currentData.yData.find(
							(item) => item.name === "累计意向客户"
						).data;
					}
					this.MyChart.setOption({
						title: {
							text: "累计意向客户/已部署客户/工位数量",
							left: "center",
							top: "2%",
							textStyle: {
								color: "#fff",
								fontWeight: "normal",
								fontSize: "18px",
							},
						},
						tooltip: {
							trigger: "axis",
							textStyle: {
								align: "left",
							},
							axisPointer: {
								type: "cross",
								label: {
									backgroundColor: "#6a7985",
								},
								extraCssText: "letter-spacing:1px;",
							},
							formatter: function(params) {
								let relVal = params[0].name;
								for (let i = 0; i < params.length; i++) {
									if(params[i].seriesName === '累计意向客户'){
										relVal +=
										'<div style="width:180px"><span style="display: inline-block;width: 10px;border: 2px solid #bfdeff;margin-bottom:4px;margin-right:5px"></span>' +
										params[i].seriesName +
										'<span style="text-align:right;float:right">' +
										params[i].value +
										"</span>" +
										"</div>";
									}else if(params[i].seriesName === '已部署客户'){
										relVal +=
										'<div style="width:180px"><span style="display: inline-block;width: 10px;border: 2px dashed #edb212;margin-bottom:4px;margin-right:5px"></span>' +
										params[i].seriesName +
										'<span style="text-align:right;float:right">' +
										params[i].value +
										"</span>" +
										"</div>";
									}else {
										relVal +=
										'<div style="width:180px"><span style="display: inline-block;width: 10px;border: 2px solid #23c215;margin-bottom:4px;margin-right:5px"></span>' +
										params[i].seriesName +
										'<span style="text-align:right;float:right">' +
										params[i].value +
										"</span>" +
										"</div>";
									}
								}
								return relVal;
							}
						},
						legend: {
							bottom: "3%",
							textStyle: {
								color: "#fff",
								fontSize: "14px",
								padding: [0, 10, 0, 0],
							},

							data: [
								{
									icon: "path://M30.6983 4.13281H0.164062V0.132812H30.6983V4.13281Z",
									name: "累计意向客户",
								},
								{
									icon: "path://M30.6983 4.13281H0.164062V0.132812H30.6983V4.13281Z",
									name: "已部署客户",
								},
								{
									icon: "path://M30.6983 4.13281H0.164062V0.132812H30.6983V4.13281Z",
									name: "工位数量",
								}
							],
						},
						grid: {
							// top:0,
							left: "8%",
							right: "8%",
							bottom: "20%",
						},

						xAxis: {
							type: "category",
							boundaryGap: false,
							axisLabel: {
								color: "#fff", // 更改坐标轴文字颜色
								fontSize: 14, // 更改坐标轴文字大小
							},
							data: tData,
						},
						yAxis: [
							{
								type: "value",
								position: "left",
								splitLine: {
									//Y轴线的颜色
									show: true,
									interval: "auto",
									lineStyle: {
										color: "#728986",
									},
								},
								axisTick: {
									show: false,
								},
								//y轴左侧文字颜色
								axisLine: {
									onZero: true,
									lineStyle: {
										color: "#fff",
									},
								},
								// y轴左侧文字大小
								axisLabel: {
									margin: 30,
									textStyle: {
										fontSize: 12,
									},
								},
							},
							{
								splitLine: {
									show: false, //想要不显示网格线，改为false
									lineStyle: {
										// 设置网格为虚线
										// type: 'dashed'
									},
								},
								axisLine: {
									onZero: true,
									lineStyle: {
										color: "#f9b707",
									},
								},
							},
							{
								// name: '累计收入',
								type: "value",
								position: "right",
								offset: 32,
								// 设置刻度线的颜色等样式
								axisLine: {
									onZero: true,
									lineStyle: {
										color: "rgb(35, 194, 21)",
										// width: 3
									},
								},
								splitLine: {
									show: false, //想要不显示网格线，改为false
									lineStyle: {
										// 设置网格为虚线
										// type: 'dashed'
									},
								},
							},
						],
						series: [
							{
								name: "累计意向客户",
								type: "line",
								stack: "Total",
								yAxisIndex: 0,
								symbolSize: 8,
								symbol: "circle",
								// smooth: true,//是否平滑曲线显示
								lineStyle: {
									color: "#bfdeff",
									width: 3,
									// type: 'dotted'
								},
								itemStyle: {
									color: "#bfdeff",
								},
								data: xData3,
							},
							{
								name: "已部署客户",
								type: "line",
								stack: "Total",
								symbolSize: 8,
								symbol: "circle",
								yAxisIndex: 1,
								// splitLine: {
								//   lineStyle: {
								//     color: ['red']
								//   }
								// },
								lineStyle: {
									color: "#f9b707",
									width: 3,
									// type: 'dotted'
								},
								itemStyle: {
									//累计收入
									normal: {
										color: "#f9b707",
									},
								},

								data: xData2,
							},
							{
								name: "工位数量",
								type: "line",
								stack: "Total",
								yAxisIndex: 2,
								symbolSize: 8,
								symbol: "circle",
								itemStyle: {
									normal: {
										color: "#23c215",
									},
								},
								lineStyle: {
									color: "#23c215",
									width: 3,
									// type: 'dashed'
								},
								data: xData1,
							},
						],
					});
				}
			});
			this.loading = false;
			// apiGetCustomerStatus().then((res) => {
			// 	this.loading = false;
			// 	const showData = res?.data?.data;
			// 	this.MyChart.setOption();
			// });

			setTimeout(() => {
				this.$nextTick(() => {
					this.MyChart.resize();
				});
			}, 0);
		},
		sizechange() {
			this.MyChart.resize();
		},
	},
	mounted() {
		this.createChart();
		this.isShow= sessionStorage.getItem("isShow")
	},
};
</script>

<style>
.son-title {
	padding: 8px;
	font-size: 18px;
	color: #fff;
}
.vue-chart-setting {
	position: absolute;
	width: 20px;
	height: 20px;
	top: 2px;
	right: 24px;
	background: url("../../assets/settings.svg");
	background-size: 16px 16px;
	background-position: 100% 100%;
	background-repeat: no-repeat;
	background-origin: content-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	cursor: pointer;
}

.vue-chart-close {
	position: absolute;
	width: 20px;
	height: 20px;
	top: 2px;
	right: 2px;
	background: url("../../assets/x.svg");
	background-size: 18px 18px;
	background-position: 100% 100%;
	background-repeat: no-repeat;
	background-origin: content-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	cursor: pointer;
}
</style>
