<!--今日工时-->
<template>
	<div
		@click="sizechange"
		v-loading="loading"
		:style="{ width: '100%', height: '100%', overflow: 'hidden' }"
	>
		<div class="circle-title">今日工时</div>
		<div class="today_work_hours_pie">
			<div id="progress"></div>
			<div class="text-item">
				<div>环比昨日</div>
				<div class="today-num" :style="{color:(dayrate<0?'#3FA864':'')}" >
					{{ dayrate=='NaN'||dayrate=='Infinity'?0+'%':dayrate+'%' }}
				</div>
			</div>
			<div class="text-item">
				<div>昨日工时</div>
				<div class="today-num">{{ this.cData.last_day_work_hour }}h</div>
			</div>
		</div>
	</div>
	<span v-show="isShow!=='1'" class="vue-chart-close" @click="handleCloseChart"> </span>
</template>

<script>
import { apiGetCurrentdayWorkingTime } from "@/api/ChartsDataRequest";
import Progress from "./todayWorkHour.js";
let progressDom = null;
let progressData = {};
export default {
	name: "customer_status_profile",
	props: ["xref", "type", "chartData"],
	emits: ["closeChart"],
	data() {
		return {
			option: {},
			loading: false,
			cData: {},
			dayrate:0,
			isShow:''
		};
	},
	methods: {
		// 图表关闭
		handleCloseChart() {
			this.$emit("closeChart");
		},

		// 图表创建
		createChart() {
			progressDom = new Progress(progressData);
			progressDom.init();
		},
		sizechange() {
			progressDom.remove();
			setTimeout(() => {
				progressDom = new Progress(progressData);
				progressDom.init();
			}, 200);
		},
	},
	mounted() {
		apiGetCurrentdayWorkingTime().then((res) => {
			if (res?.data?.code == 200) {
				this.cData = res?.data?.data;
				// 环比昨日 = （今日工时-昨日工时）/昨日工时
				let digit = (res.data.data.daily_work_hour - res.data.data.last_day_work_hour)/res.data.data.last_day_work_hour*100
				if(Number.isInteger(digit)){//判断否为整数
					this.dayrate = digit
				}else{
					this.dayrate = digit.toFixed(1)
				}
				progressData = {
					dom: document.getElementById("progress"),
					innerRadius: 60, //内圆半径
					outerRadius: 70, //外圆半径
					fontSize: 22,
					fontColor: "#fff",
					suffix: " ",
					// extra: '<div style="color:#fff;font-size:16px">今日工时</div>',
					duration: 1000,
					value:this.cData.daily_work_hour==0?'0':Math.round(this.cData.daily_work_hour /this.cData.standard_hour),
					customRingValue:this.cData.daily_work_hour+'h'+'<div style="color:#fff;font-size:16px">今日工时</div>',
					
					textDomClass:'chartCustomRingValue_center',
					color: [
						["rgb(255, 233, 175)", 0],
						["rgb(255, 200, 165)", 1],
					],
					
				
				};
				
				setTimeout(() => {
					this.createChart();
				}, 800);
			}
		});
		this.isShow= sessionStorage.getItem("isShow")
	},
};
</script>

<style>
.vue-chart-setting {
	position: absolute;
	width: 20px;
	height: 20px;
	top: 2px;
	right: 24px;
	background: url("../../assets/settings.svg");
	background-size: 16px 16px;
	background-position: 100% 100%;
	background-repeat: no-repeat;
	background-origin: content-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	cursor: pointer;
}

.vue-chart-close {
	position: absolute;
	width: 20px;
	height: 20px;
	top: 2px;
	right: 2px;
	background: url("../../assets/x.svg");
	background-size: 18px 18px;
	background-position: 100% 100%;
	background-repeat: no-repeat;
	background-origin: content-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	cursor: pointer;
}
.circle-title {
	text-align: center;
	color: #fff;
	font-size: 18px;
	margin-top: 8px;
}
#progress {
	height: 70%;
	width: 100%;
	/* background: rgb(248, 3, 3); */
}

.today_work_hours_pie {
	height: 100%;
	width: 100%;
	flex-direction: column;
}
.today_work_hours_pie .text-item {
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	font-size: 14px;
	color: #fff;
}
.today_work_hours_pie .text-item > div:first-child {
	color: #fff;
	font-size: 14px;
}

.today_work_hours_pie .text-item > div:last-child {
	/* font-size: 18px; */
	color: #f9b707;
	padding-left: 20px;
}
.today-num {
	font-size: 18px;
}
</style>
