<!-- 本月工位产能排名TOP10 -->
<template>
	<div class="strip_wrap" v-loading="loading">
		<div class="son-title">本月工位产能排名TOP10</div>
    <div class="tips" v-if="list.length===0" ></div>
		<div
			class="strip_item"
			v-for="(item, i) in list"
			:key="i"
		>
			<div class="strip_text">
				<div>No.{{ i + 1 }}</div>
				<div>{{ item.name }}</div>
			</div>
			<div class="strip">
				<div>
					<span
						:style="{ width: (this.maxTime!==0?item.value / this.maxTime:item.value) * 100+'%'  }"
					></span>
				</div>
				<div >{{ item.value+'pcs'}}</div>
			</div>
		</div>
	</div>
	<span v-show="isShow!=='1'" class="vue-chart-close" @click="handleCloseChart"></span>
  <img src="@/assets/sort.png" @click="sort" class="sort" >
</template>
<script>
import { apiCurrentMonthWorkingTime } from "@/api/ChartsDataRequest";
import dayjs from "dayjs"
export default {
	name: "day_workstation_working_hours_ranking",
	props: [],
	emits: ["closeChart"],
	data() {
		return {
			list: [],
			maxTime: "",
			loading: true,
			order:true,
			isShow:''
		};
	},
	methods: {
		// 图表关闭
		handleCloseChart() {
			this.$emit("closeChart");
		},
		// 图表创建
		async createChart(order) {
      this.loading = true;
      const monthStartTimestamp=dayjs().startOf('month').unix()
      const res= await apiCurrentMonthWorkingTime({
        start_time:monthStartTimestamp,
        field:"actual_output",
        page_rows:'10',
        order:order
      })
				if (res.data.code == 200) {
        this.list = res?.data?.data || [];
        this.maxTime= Math.max.apply(Math,this.list.map(item => { return item.value }))
      
        // console.log(this.maxTime);
      }
      this.loading = false;
		},
		sort(){
			this.order= !this.order
			this.createChart(this.order?'desc':'asc');
		}
	},
	mounted() {
		this.createChart('desc');
		this.isShow= sessionStorage.getItem("isShow")
	},
};
</script>
<style lang="scss" scoped>
.son-title {
	padding: 8px;
	font-size: 18px;
}
.strip_wrap {
	padding: 0 20px;
	color: #fff;
	--bg-start: rgb(61, 145, 80); //设置开始的渐变颜色
	--bg-end: rgb(61, 159, 98); //设置结束的渐变颜色
}

.strip_wrap .strip_item .strip_text {
	display: flex;
	font-size: 14px;
	line-height: 30px;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}

.strip_wrap .strip_item .strip_text :nth-child(1) {
	color: #3D9F62;
	padding-right: 10px;
}
.strip_wrap .strip_item .strip {
	flex: 1;
	// height: 10px;
	display: flex;
	align-items: center;
}
.strip_wrap .strip_item .strip :nth-child(1) {
	background-color: #2b4e4a;
	flex: 1;
	height: 10px;
	position: relative;
}
.strip_wrap .strip_item .strip :nth-child(1) span {
	display: block;
	height: 10px;
	width: 90%;
	position: absolute;
	left: 0;
	/* Firefox 3.6+ */
	background: -moz-linear-gradient(
		to right,
		var(--bg-start) 30%,
		var(--bg-end) 100%
	);
	/* Safari 4-5, Chrome 1-9 */
	background: -webkit-gradient(
		to right,
		var(--bg-start) 30%,
		var(--bg-end) 100%
	);
	/* Safari 5.1+, Chrome 10+ */
	background: -webkit-linear-gradient(
		to right,
		var(--bg-start) 30%,
		var(--bg-end) 100%
	);
	/* Opera 11.10+ */
	background: -o-linear-gradient(
		to right,
		var(--bg-start) 30%,
		var(--bg-end) 100%
	);
	background: linear-gradient(
		to right,
		var(--bg-start) 30%,
		var(--bg-end) 100%
	);
	/*IE<9>*/
	filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0, startColorstr=var(--bg-start),endColorstr=var(--bg-end));

	/*IE8+*/
	-ms-filter: "progid:DXImageTransform.Microsoft.gradient(GradientType=0, startColorstr=var(--bg-start), endColorstr=var(--bg-end))";
}
.strip_wrap .strip_item .strip :nth-child(2) {
	min-width: 30px;
	// padding: 0 10px;
	text-align: right;
  min-width: 60px;
}
.vue-chart-close {
	position: absolute;
	width: 20px;
	height: 20px;
	top: 2px;
	right: 2px;
	background: url("../../assets/x.svg");
	background-size: 18px 18px;
	background-position: 100% 100%;
	background-repeat: no-repeat;
	background-origin: content-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	cursor: pointer;
}

</style>