import request from '@/utils/request'
import dayjs from "dayjs"
const dayStartTimestamp=dayjs().startOf('date').unix()
const monthStartTimestamp=dayjs().startOf('month').unix()

// 图表客户状态统计
export function apiGetCustomerStatus(data) {
  return request({
    url: '/chartsapi/api/admin/bulletin/customer-status',
    method: 'get',
    data: data
  })
}


// 机器人部署数量统计
export function apiGetRobotDeployData(data) {
  return request({
    url: '/chartsapi/api/admin/operate/deploy-robot',
    method: 'get',
    data: data
  })
}


// 机器人12个月数量统计
export function apiGetRobotNumChange(data) {
  return request({
    url: '/chartsapi/api/admin/robot/month-change',
    method: 'get',
    data: data
  })
}


// 前十大用机大户
export function apiGetTopUserAndIncome(data) {
  return request({
    url: '/chartsapi/api/admin/operate/customer-hour',
    method: 'get',
    data: data
  })
}


// 机器人上岗率数据
export function apiGetRobotWorkingRate(data) {
  return request({
    url: '/chartsapi/api/admin/operate/month-robot',
    method: 'get',
    data: data
  })
}

// 人力结构-学历
export function apiGetWorkerStructure() {
  return request({
    url: '/chartsapi/api/admin/operate/educate',
    method: 'get',
  })
}

// 人力结构-岗位
export function apiHumanStructureJob() {
  return request({
    url: `/chartsapi/api/admin/operate/educate?dict_identification=charts_post`,
    method: 'get',
  })
}

// 派遣总台数、派遣累计收入、总工时
export function apiGetSenderStatus() {
  return request({
    url: '/chartsapi/api/admin/operate/month-deploy',
    method: 'get',
  })
}


// 意向客户、已部署客户、工位数量
export function apiGetCustomerAndDeployed() {
  return request({
    url: '/chartsapi/api/admin/operate/month-customer',
    method: 'get',
  })
}


// 意向客户、已部署客户、工位数量
export function apiGetCustomerCanceled() {
  return request({
    url: '/chartsapi/api/admin/bulletin/customer-stop',
    method: 'get',
  })
}


// 当前部署机器人数
export function apiGetRobotDeployedNum() {
  return request({
    url: '/chartsapi/api/admin/robot/deploy-add',
    method: 'get',
  })
}

// 本月客户数对比上月 
export function apiCustomerJionedCompare() {
  return request({
    url: `/chartsapi/api/admin/bulletin/customer-month-add`,
    method: 'get',
  })
}


// 本月工时分析
export function apiRobotWorkedTime() {
  return request({
    url: `/chartsapi/api/admin/hour/month-work-analysis`,
    method: 'get',
  })
}


// 本月客户工时排名
export function apiCurrentMonthTopTen(rows,order) {
  return request({
    url: `/chartsapi/api/admin/hour/month-customer?start_time=${monthStartTimestamp}&page_rows=${rows}&order=${order}`,
    method: 'get',
  })
}





// 今日工位预计收入 top 10
export function apiCurrentDayReceivedTopTen() {
  return request({
    url: `/chartsapi/api/admin/income/daily-station?start_time=${dayStartTimestamp}&page_rows=10`,
    method: 'get',
  })
}

// 今日工位预计收入 top 5
export function apiCurrentDayReceivedTopFive() {
  return request({
    url: `/chartsapi/api/admin/income/daily-station?start_time=${dayStartTimestamp}&page_rows=5`,
    method: 'get',
  })
}


// 本月客户工时分析
export function apiGetAnalysisWorkingTime() {
  return request({
    url: `/chartsapi/api/admin/hour/month-customer-analysis`,
    method: 'get',
  })
}


// 本月总工时
export function apiGetMonthAssembleTime() {
  return request({
    url: `/chartsapi/api/admin/hour/month-work`,
    method: 'get',
  })
}


// 今日工时/环比昨日
export function apiGetCurrentdayWorkingTime() {
  return request({
    url: `/chartsapi/api/admin/hour/daily-work`,
    method: 'get',
  })
}
// 今日工位工时排名TOP10
// export function day_workstation_today_station_time(order='desc') {
//   return request({
//     url: `/chartsapi/api/admin/hour/daily-station`,
//     method: 'get',
//     params:{
//       field:'actual_production_time',
//       page_rows:10,
//       order,
//       //1676217600
//       start_time:dayStartTimestamp,
//     }
//   })
// }

// 今日异常待料排名TOP10  本月工位工时Top10  今日异常待料排名TOP10
export function apiCurrentMonthWorkingTime(data) {
  return request({
    url: `/chartsapi/api/admin/hour/daily-station`,
    method: 'get',
    params: data
  })
}


// 今日平均工时/环比昨日 
export function apiGetCurrentdayAverageTime() {
  return request({
    url: `/chartsapi/api/admin/hour/daily-work-avg`,
    method: 'get',
  })
}

// 今天正常待料/今日异常待料
export function apiGetCurrentdayNormalMaterial(data) {
  return request({
    url: `/chartsapi/api/admin/hour/daily-waiting`,
    method: 'get',
    params:data
  })
}
// 今日工位预期收入排名TOP10
export function day_workstation_expect_income_ranking(order,isDay=true,page_rows=10) {
  return request({
    url: `/chartsapi/api/admin/income/daily-station`,
    method: 'get',
    params: {
      page_rows,
      order,
      //1676217600
      start_time:isDay?dayStartTimestamp:monthStartTimestamp,
    }
  })
}
// 客户预期收入排名TOP10
export function custom_expect_income_ranking(order,page_rows=10) {
  return request({
    url: `/chartsapi/api/admin/income/month-customer`,
    method: 'get',
    params: {
      page_rows,
      order,
      //1676217600
      start_time:monthStartTimestamp,
    }
  })
}
// 本月单机平均预期收入
export function moon_stand_alone_average_expected_income() {
  return request({
    url: `/chartsapi/api/admin/income/month-work-avg`,
    method: 'get',
    
  })
}
// 本月预期总收入
export function total_moon_expected_income() {
  return request({
    url: `/chartsapi/api/admin/income/month-work`,
    method: 'get',
    
  })
}
// 本月预期收入分析
export function month_expect_income_analyze() {
  return request({
    url: `/chartsapi/api/admin/income/month-work-analysis`,
    method: 'get',
    
  })
}

// 本月客户稼动率排名TOP5(10)
export function month_custom_utilization_rate_ranking(order,rows) {
  return request({
    url: `/chartsapi/api/admin/efficient/month-customer?page_rows=${rows}&order=${order}`,
    method: 'get',
    
  })
}

// 本月总稼动率
export function month_assemble_utilization() {
  return request({
    url: `/chartsapi/api/admin/efficient/month-analysis`,
    method: 'get',
  })
}

// 今日工位稼动率排名TOP10
export function today_station_utilization_ranking(order) {
  return request({
    url: `/chartsapi/api/admin/efficient/station-analysis?page_rows=10&order=${order}`,
    method: 'get',
  })
}

// 今日稼动率/环比昨日
export function today_utilization_rate() {
  return request({
    url: `/chartsapi/api/admin/efficient/daily-analysis`,
    method: 'get',
  })
}

// 本月稼动率分析
export function month_utilization_rate_analyze() {
  return request({
    url: `/chartsapi/api/admin/efficient/month-statistics`,
    method: 'get',
  })
}

// 本月工位稼动率排名TOP5
export function month_work_utilization_rate_ranking(order) {
  return request({
    url: `/chartsapi/api/admin/efficient/month-station?page_rows=5&order=${order}`,
    method: 'get',
  })
}

// 机器人总稼动率月度变化
export function robot_utilization_rate_monthly_changes() {
  return request({
    url: `/chartsapi/api/admin/efficient/year-analysis`,
    method: 'get',
  })
}


// 12个月客户/工位数量变化
export function month_work_number_change() {
  return request({
    url: `/chartsapi/api/admin/bulletin/customer-month-status`,
    method: 'get',
  })
}
// 客户产能/工时数据看板
export function month_actual(data) {
  return request({
    url: `/chartsapi/api/admin/hour/month-actual`,
    method: 'get',
    params:data
  })
}
// 客户名称搜索
export function customer_manage(data) {
  return request({
    url: `/rmp/api/admin/customer-manage/list`,
    method: 'get',
    params:data
  })
}
// 工位编号/名称
export function getWorkStationList(data) {
  return request({
    url: `/rmp/api/admin/work-station-manage/list`,
    method: 'get',
    params:data
  })
}

// 客户数量分状态统计
export function custom_number_state() {
  return request({
    url: `/chartsapi/api/admin/bulletin/customer-status`,
    method: 'get',
  })
}

// 订单数量对比上月
export function orderforgoods_number() {
  return request({
    url: `/chartsapi/api/admin/order/month-add`,
    method: 'get',
  })
}

// 订单分状态统计展示
export function orderforgoods_state() {
  return request({
    url: `/chartsapi/api/admin/order/status-count`,
    method: 'get',
  })
}

// 12个月工位数量变化
export function month_station_number_change() {
  return request({
    url: `/chartsapi/api/admin/station/month-count`,
    method: 'get',
  })
}

// 工位数量对比上月
export function station_number_contrast(type) {
  return request({
    url: `/chartsapi/api/admin/station/month-change?type=${type}`,
    method: 'get',
  })
}

// 当前部署机器人数对比
export function current_arrange_robot_contrast() {
  return request({
    url: `/chartsapi/api/admin/robot/deploy-add`,
    method: 'get',
  })
}

// 用户任务数量统计
export function my_tasks(data={}) {
  return request({
    url: `/rmp/api/admin/flow-engine/user-task-statistics`,
    method: 'get',
    params:data
  })
}
export function labelList(data={}) {
  return request({
    url: `/rmp/api/admin/data-dict/label-list`,
    method: 'get',
    params:data
  })
}
// 单台机器人月工时/产能/收入峰值标
export function robotPeak(data) {
  return request({
    url: `/chartsapi/api/admin/settle/robot-peak-value`,
    method: 'get',
    params:data
  })
}

// 客户阶段统计
export function customerStage(data) {
  return request({
    url: `/chartsapi/api/admin/bulletin/customer-stage-analysis`,
    method: 'get',
    params:data
  })
}

// 需求阶段统计
export function demandStage(data) {
  return request({
    url: `/chartsapi/api/admin/bulletin/demand-stage-analysis`,
    method: 'get',
    params:data
  })
}
// 方案阶段统计
export function schemeStage(data) {
  return request({
    url: `/chartsapi/api/admin/bulletin/scheme-stage-analysis`,
    method: 'get',
    params:data
  })
}
// 合同阶段统计
export function contractStage(data) {
  return request({
    url: `/chartsapi/api/admin/bulletin/contract-stage-analysis`,
    method: 'get',
    params:data
  })
}
// 交付阶段统计
export function deliveryStage(data) {
  return request({
    url: `/chartsapi/api/admin/bulletin/delivery-stage-analysis`,
    method: 'get',
    params:data
  })
}
// 运维阶段统计
export function maintainStage(data) {
  return request({
    url: `/chartsapi/api/admin/bulletin/maintain-stage-analysis`,
    method: 'get',
    params:data
  })
}
// 交付看板-选择工单下拉
export function workOrderSelectMap(data) {
  return request({
    url: `/chartsapi/api/admin/station/work-order-select-map`,
    method: 'get',
    params: data
  })
}
// 交付看板-详情
export function deliverySimulateDegree(data) {
  return request({
    url: `/chartsapi/api/admin/bulletin/delivery-simulate-degree`,
    method: 'get',
    params: data
  })
}
// 交付看板-添加新看板
export function saveBoard(data) {
  return request({
    url: `/chartsapi/api/admin/bulletin/save-board`,
    method: 'post',
    params: data
  })
}
// 交付看板-删除看板
export function delBoard(data) {
  return request({
    url: `/chartsapi/api/admin/bulletin/del-board`,
    method: 'delete',
    params: data
  })
}

// 交付-验证/备料
export function node_check_prepare(data) {
  return request({
    url: `/chartsapi/api/admin/delivery/node_check_prepare`,
    method: 'get',
    params: data
  })
}

// 交付-现场部署中
export function node_scene_deploy(data) {
  return request({
    url: `/chartsapi/api/admin/delivery/node_scene_deploy`,
    method: 'get',
    params: data
  })
}

// 交付-运营
export function delivery_operate_analysis(data) {
  return request({
    url: `/chartsapi/api/admin/bulletin/delivery-operate-analysis`,
    method: 'get',
    params: data
  })
}

// 交付-运营
export function demand_stage_analysis_new(data) {
  return request({
    url: `/chartsapi/api/admin/bulletin/demand-stage-analysis-new`,
    method: 'get',
    params: data
  })
}