<!--机器人总稼动率月度变化-->
<template>
	<div
		@click="sizechange"
		ref="MyChartDom"
		v-loading="loading"
		:style="{ width: '100%', height: '100%', overflow: 'hidden' }"
	></div>
	<span v-show="isShow!=='1'" class="vue-chart-close" @click="handleCloseChart"> </span>
</template>

<script>
import * as echarts from "echarts";
import { robot_utilization_rate_monthly_changes } from "@/api/ChartsDataRequest";

export default {
	name: "robot_employment_rate",
	props: ["xref", "type", "chartData"],
	emits: ["closeChart"],
	data() {
		return {
			option: {},
			loading: true,
			isShow:''
		};
	},
	methods: {
		// 图表关闭
		handleCloseChart() {
			this.$emit("closeChart");
		},

		// 图表创建
		createChart() {
			this.ChartDom = this.$refs.MyChartDom;
			this.MyChart = echarts.init(this.ChartDom, null);
			robot_utilization_rate_monthly_changes().then((res) => {
				this.loading = false;
				let showData = res?.data?.data;
				let xData =showData.xData;
				let yData = [];
				showData.yData.forEach((item) => {
					yData =item.data.map(v=>{
						return v.replace('%', '')
					});
				});
				this.MyChart.setOption({
					grid: {
						left: "50",
						right: "10",
						top: "25%",
						bottom: "16%",
					},
					color: ["rgba(129, 218, 40, 1)"],
					tooltip: {
						trigger: "axis", //触发类型；轴触发，item项目触发，
						axisPointer: {
							type: "cross",
							label: {
								backgroundColor: "#6a7985",
							},
							// crossStyle: {
							//   color: 'red'
							// }
						},
						formatter: `{b} {c}%`,
					},
					xAxis: {
						type: "category",
						data: xData,
						axisLabel: {
							// inside: true,
							color: "#fff",
						},
					},

					yAxis: {
						type: "value",
						axisLine: {
							lineStyle: {
								color: "#fff",
								width: 2,
							},
						},
						splitLine: {
							//Y轴线的颜色
							show: true,
							interval: "auto",
							lineStyle: {
								color: ["#728986"],
							},
						},
						axisLabel: {
							show: true,
							interval: "auto",
							formatter: "{value} %",
						},
					},
					title: {
						text: "机器人总稼动率月度变化",
						left: "center",
						top: "2%",
						textStyle: {
							color: "#fff",
							fontWeight: "normal",
							fontSize: "18px",
						},
					},
					series: [
						{
							data: yData,
							type: "bar",
							showBackground: false,
							barWidth: 16,
						},
					],
				});
			});
			this.loading = false;

			setTimeout(() => {
				this.$nextTick(() => {
					this.MyChart.resize();
				});
			}, 0);
		},
		sizechange() {
			this.MyChart.resize();
		},
	},
	mounted() {
		this.createChart();
		this.isShow= sessionStorage.getItem("isShow")
	},
};
</script>

<style>
.vue-chart-setting {
	position: absolute;
	width: 20px;
	height: 20px;
	top: 2px;
	right: 24px;
	background: url("../../assets/settings.svg");
	background-size: 16px 16px;
	background-position: 100% 100%;
	background-repeat: no-repeat;
	background-origin: content-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	cursor: pointer;
}

.vue-chart-close {
	position: absolute;
	width: 20px;
	height: 20px;
	top: 2px;
	right: 2px;
	background: url("../../assets/x.svg");
	background-size: 18px 18px;
	background-position: 100% 100%;
	background-repeat: no-repeat;
	background-origin: content-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	cursor: pointer;
}
</style>