<!--已终止客户数-->
<template>
	<div class="chart-drobot-title">已终止客户</div>
	<div class="robot-data">
		<svg viewBox="0 0 132 132" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M61.1492 76.5981C62.908 76.8693 64.6913 77.0077 66.485 77.0089C71.1794 77.0122 75.8284 76.0753 80.1664 74.2519C84.5043 72.4285 88.446 69.7543 91.7663 66.3822C95.0867 63.0101 97.7204 59.0061 99.5171 54.5991C101.314 50.1922 102.238 45.4686 102.237 40.6985C102.236 33.5195 100.14 26.502 96.2148 20.5332C92.2893 14.5643 86.7103 9.91205 80.1832 7.16449C73.6561 4.41693 66.4738 3.69743 59.5443 5.09693C52.6148 6.49644 46.2492 9.95212 41.2522 15.0271C36.2552 20.1022 32.8511 26.5687 31.4703 33.6092C30.0895 40.6497 30.7939 47.9482 33.4945 54.582C35.998 60.7316 40.1127 66.0541 45.3779 69.9783C36.9064 72.6412 29.1139 77.371 22.7299 83.8601C12.4298 94.3298 6.63786 108.524 6.625 123.328V127.884H112.472V118.777H15.8122C16.9399 107.236 22.2446 96.5306 30.6981 88.737C39.0486 81.0383 49.8841 76.7225 61.1492 76.5981ZM66.485 13.5135C61.1993 13.5233 56.0351 15.1249 51.6449 18.1159C47.2547 21.1069 43.8356 25.3531 41.8195 30.318C39.8034 35.2829 39.2809 40.7436 40.318 46.0101C41.3551 51.2766 43.9052 56.1126 47.6461 59.9069C51.387 63.7013 56.1508 66.2838 61.3356 67.328C66.5204 68.3722 71.8935 67.8313 76.7759 65.7737C81.6583 63.7161 85.8309 60.2341 88.7665 55.7677C91.702 51.3013 93.2688 46.0509 93.2688 40.68C93.2688 37.1083 92.5757 33.5716 91.2291 30.2724C89.8825 26.9732 87.9088 23.9762 85.4211 21.4529C82.9333 18.9296 79.9803 16.9296 76.731 15.5672C73.4818 14.2049 70 13.507 66.485 13.5135ZM112.483 80.9707H83.5234V90.0838H112.483V80.9707ZM112.481 99.0879H63.2578V108.201H112.481V99.0879Z"
				fill="#F56C6C"
			/>
		</svg>
		<div class="text-item">
			<div>{{ customerCanceledNum }}</div>
			<div>已终止客户数量</div>
		</div>
		<span v-show="isShow!=='1'" class="vue-chart-close" @click="handleCloseChart"> </span>
	</div>
</template>
<script>
import { apiGetCustomerCanceled } from "@/api/ChartsDataRequest";
export default {
	name: "terminated_customer",
	props: [],
	emits: ["closeChart"],
	data() {
		return {
			customerCanceledNum: 0,
			loading: true,
			isShow:''
		};
	},
	methods: {
		// 图表关闭
		handleCloseChart() {
			this.$emit("closeChart");
		},

		// 图表创建
		createChart() {
			this.loading = false;
			apiGetCustomerCanceled().then((res) => {
				if (res.data.code == 200) {
					const currentData = res?.data?.data || 0;
					this.customerCanceledNum = currentData;
				} else {
					console.log("Robot Data request fail");
				}
				this.loading = false;
			});
		},
	},
	mounted() {
		this.createChart();
		this.isShow= sessionStorage.getItem("isShow")
	},
};
</script>

<style lang="scss" scoped>
.chart-drobot-title {
	color: #fff;
	font-size: 18px;
	padding-top: 8px;
}
.robot-data {
	// height: 100%;
	height: calc(100% - 24px);
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	svg {
		width: calc((100% / -130) * 100);
		height: calc((100% / 130) * 100 - 60px);
	}

	.text-item {
		text-align: center;
		color: #fff;
		line-height: 40px;
		div:first-child {
			font-size: 40px;
			padding-top: 10px;
			color: #f9b707;
		}

		div:last-child {
			font-size: 16px;
		}
	}
}
</style>
